@import '~sanitize.css';
@import '~@angular/cdk/overlay-prebuilt.css';
input[type=date] {
  position: relative;
  width: 100%;
  height: 38px;
  font-size: 16px;
  color: #000;
  padding: 10px;
  appearance: none;
  border: 1px solid #bababa;
  background-color: #fff;
  text-transform: uppercase;
}
input[type=date]:not(:disabled)::after {
  content: "\f073";
  color: #255dbd;
  font-family: FontAwesome;
  font-style: normal;
  position: absolute;
  right: 10px;
  top: 10px;
}
input[type=date]:hover {
  outline-color: #255dbd;
}
input[type=date].brand {
  color: #fff;
  background-color: #255dbd;
  font-weight: bold;
  height: 43px;
  border-radius: 2px;
  border: 1px solid #255dbd;
}
input[type=date].brand::after {
  color: #fff;
  font-weight: normal;
}
input[type=date].brand:hover {
  outline-color: transparent;
}

input[type=date]::-webkit-calendar-picker-indicator {
  content: "\f073";
  position: relative;
  left: 10px;
  z-index: 1000;
  color: transparent;
  background: transparent;
}

input[type=date]::-webkit-inner-spin-button {
  z-index: 1;
}

input[type=date]::-webkit-clear-button {
  display: none;
}

input[type=date]::-ms-clear {
  display: none;
}

input[type=date]:disabled {
  background-color: #ebebe4;
  color: graytext;
}

.dropdown {
  position: relative;
  width: 100%;
  font-size: 16px;
}
.dropdown:not(.no-arrow)::after {
  content: "\f078";
  font-family: FontAwesome;
  color: #255dbd;
  position: absolute;
  pointer-events: none;
  right: 10px;
  top: 10px;
}
.dropdown.brand::after {
  top: 13px;
  color: #fff;
}
.dropdown.brand.disabled::after {
  display: none;
}
.dropdown.brand.disabled select {
  opacity: 0.7;
}
.dropdown.brand select {
  font-weight: bold;
  height: 43px;
  color: #fff;
  background-color: #255dbd;
  border-radius: 2px;
  outline-color: transparent;
  border: 1px solid #255dbd;
}
.dropdown.brand select.ng-pristine {
  color: #fff;
  font-style: normal;
}
.dropdown.brand select.ng-pristine option:not(:disabled) {
  color: #fff;
}
.dropdown select {
  font-weight: normal;
  border: 1px solid #bababa;
  outline-color: #255dbd;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  appearance: none;
  padding-right: 30px;
}
.dropdown select::-ms-expand {
  display: none;
}
.dropdown select.ng-pristine {
  color: graytext;
}
.dropdown select.ng-pristine option:not(:disabled) {
  color: #000;
}
.dropdown select:disabled {
  background-color: #ebebe4;
}
.dropdown option {
  font-style: normal;
}

input[type=text],
input[type=number],
input[type=tel],
[type=password] {
  border: 1px solid #bababa;
  outline-color: #255dbd;
  font-size: 16px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=checkbox] + .checkbox-visual {
  cursor: pointer;
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  background: #fff;
  border: 3px solid #255dbd;
  border-radius: 2px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type=checkbox]:checked + .checkbox-visual {
  color: #fff;
  background: #255dbd;
  border: 3px solid #255dbd;
}

input[type=checkbox]:checked + .checkbox-visual::after {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 18px;
  color: white;
}

input[type=checkbox].small + .checkbox-visual {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

input[type=checkbox]:checked.small + .checkbox-visual::after {
  content: "\f00c";
  font-size: 14px;
}

input[type=radio] + .radio-visual {
  cursor: pointer;
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  background: #fff;
  border: 2px solid #255dbd;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 2px;
}

input[type=radio]:checked + .radio-visual::after {
  content: "";
  display: block;
  border-radius: 50%;
  font-weight: bold;
  border: 2px #255dbd solid;
  background-color: #255dbd;
  width: 13px;
  height: 13px;
  position: relative;
  top: 3px;
  left: 3px;
}

.radio-item-group {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

select,
input {
  width: 100%;
  padding: 10px;
}

input::placeholder,
select.ng-pristine,
option:disabled {
  font-style: italic;
}

textarea {
  width: 100%;
  font-size: 16px;
  height: 8em;
  padding: 0.6em 0.5em;
  border: 2px solid #d4d4d4;
}

/*
 * The custom classes prefixed with 'notif-' should be used here where possible so they
 * keep functioning if the Swal team ever decide to change their class names
 */
.notif-container.swal2-shown {
  animation: fadeIn 0.3s;
}
.notif-container .notif-popup {
  border-radius: 5px;
  padding: 1.25rem;
}
.notif-container .notif-popup .notif-header .notif-title {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.notif-container .notif-popup .notif-content {
  font-size: 14px;
  color: #000;
}
.notif-container .notif-popup .notif-content.branded {
  margin: -1.25rem -1.25rem 0;
  padding: 1.25rem;
  color: #fff;
  background-color: #255dbd;
  font-size: 16px;
}
.notif-container .notif-popup .notif-content .withdraw-notif-content {
  border-top: #255dbd 4px solid;
  display: flex;
  align-items: center;
  margin: -15px;
  padding: 15px;
}
.notif-container .notif-popup .notif-content .withdraw-notif-content .icon {
  font-size: 30px;
  padding: 15px;
  color: #255dbd;
}
.notif-container .notif-popup .notif-content .withdraw-notif-content .text {
  font-weight: bold;
  font-size: 13px;
  text-align: justify;
}
.notif-container .notif-popup .notif-actions {
  margin: 1em auto 0;
  justify-content: space-between;
}
.notif-container .notif-popup .notif-actions button {
  min-width: 108px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
  padding: 0 12px;
}
.notif-container .notif-popup .notif-actions button.notif-cancel-button {
  background-color: #e05247;
}
.notif-container .notif-popup .notif-actions button.notif-confirm-button {
  background-color: #255dbd;
}
.notif-container .notif-popup .notif-actions.single-button button {
  width: 100%;
}
.notif-container .notif-popup .notif-footer {
  margin: 1em 0 0;
}
.notif-container .notif-popup.type-success {
  background-color: #f8fafe;
}
.notif-container .notif-popup.type-success .notif-header .notif-title {
  color: #255dbd;
}
.notif-container .notif-popup.type-warning {
  background-color: #fff7e2;
}
.notif-container .notif-popup.type-warning .notif-header .notif-title {
  color: #000;
}
.notif-container .notif-popup.type-error {
  background-color: #f9dedb;
}
.notif-container .notif-popup.type-error .notif-header .notif-title {
  color: #e05247;
}
.notif-container .notif-popup.swal2-show {
  animation: zoomIn 0.3s;
}
.notif-container .notif-toast {
  border-radius: 0;
  box-shadow: 0 0 6px #333 !important;
}
.notif-container .notif-toast .notif-content {
  font-size: 16px;
}
.notif-container .notif-toast .notif-content .toast-content {
  display: flex;
  align-items: center;
}
.notif-container .notif-toast .notif-content .toast-content .toast-icon {
  font-size: 20px;
  margin-right: 10px;
}
.notif-container .notif-toast .notif-content .toast-content .toast-text {
  width: 100%;
  text-align: center;
}
.notif-container .notif-toast.type-success {
  background-color: #255dbd;
}
.notif-container .notif-toast.type-success .notif-content {
  color: #fff;
}
.notif-container .notif-toast.type-warning {
  background-color: #fff7e2;
}
.notif-container .notif-toast.type-warning .notif-content {
  color: #000;
}
.notif-container .notif-toast.type-warning .notif-content .toast-content .toast-icon {
  color: #ffbc16;
}
.notif-container .notif-toast.type-error {
  background-color: #f9dedb;
}
.notif-container .notif-toast.type-error .notif-content {
  color: #e05247;
}
.notif-container .notif-toast.swal2-show {
  animation: slideInUp 0.5s;
}
.notif-container .notif-toast.swal2-hide {
  animation: slideOutUp 0.5s;
}
.notif-container.dialog-container {
  padding: 0 40px !important;
}

/*
 * The 'uj-notif-*' classes are used only in the account -> payments -> user-payment-journey module but the
 * styles need to be declared globally due to the way Sweetalert2 works
 */
.uj-notif-container .uj-notif-popup {
  border-radius: 8px;
  padding: 0;
}
.uj-notif-container .uj-notif-popup .uj-notif-title {
  width: 100%;
  padding: 24px 24px 0;
  margin: 0;
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #001041;
}
.uj-notif-container .uj-notif-popup .uj-notif-content {
  width: 100%;
  padding: 24px;
  text-align: justify;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
}
.uj-notif-container .uj-notif-popup .uj-notif-content p {
  margin: 0;
}
.uj-notif-container .uj-notif-popup .uj-notif-content .uj-notif-actions {
  margin: 24px -24px -24px;
  width: calc(100% + 48px);
}
.uj-notif-container .uj-notif-popup .uj-notif-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin: 0;
}
.uj-notif-container .uj-notif-popup .uj-notif-actions button {
  background: transparent;
  border: none;
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  color: #255dbd;
  outline: none;
}
.uj-notif-container .uj-notif-popup .uj-notif-actions button:nth-child(n+2) {
  margin-left: 10px;
}
.uj-notif-container .uj-notif-popup .uj-notif-actions button:disabled {
  color: rgba(0, 0, 0, 0.38);
}

/*
 * The 'vir-notif-*' classes are used only in the account -> payments -> user-payment-journey module but the
 * styles need to be declared globally due to the way Sweetalert2 works
 */
.virtuals-notif-container {
  font-family: "Roboto Condensed", sans-serif;
}
.virtuals-notif-container .virtuals-notif-popup {
  border-radius: 8px;
  padding: 16px;
}

/**
* Styles for the 'redesign' module of notifications.
* The styles are declared globally due to the architectural requirements of the Sweetalert2 library.
*/
.notif-container.module-redesign .type-info .swal2-header {
  align-items: flex-start;
}
.notif-container.module-redesign .type-info .swal2-header .notif-title {
  padding: 9px 9px 12px;
  font-size: 20px;
  font-weight: normal;
  text-transform: unset;
  color: rgba(0, 12, 45, 0.87);
}
.notif-container.module-redesign .type-info .swal2-content {
  padding: 1.5em 0.7em 0.9em;
  text-align: left;
  color: rgba(0, 12, 45, 0.6);
}
.notif-container.module-redesign .type-info .notif-actions {
  justify-content: flex-end;
}
.notif-container.module-redesign .type-info .notif-actions .swal2-confirm {
  width: 100%;
  height: 42px;
  margin: 0 0 24px;
  border-radius: 50px;
  color: #001041;
  background: #1affff;
}
.notif-container.module-redesign .type-info .notif-actions .swal2-cancel {
  padding: 0;
  min-width: auto;
  margin: 12px 0 0;
  color: #255dbd;
  background-color: transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes slideInUp {
  from {
    bottom: -100%;
    visibility: visible;
  }
  to {
    bottom: 0;
  }
}
@keyframes slideOutUp {
  from {
    bottom: 0;
  }
  to {
    visibility: hidden;
    bottom: 100%;
  }
}
.overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.bet-loading {
  max-width: 335px;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  height: 144px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.bet-loading app-loading-bar {
  width: 100%;
  margin-top: -19px;
}
.bet-loading .loading-text {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.collapsible {
  transition: height 0.3s ease-in;
  overflow: hidden;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:active::after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.bold {
  font-weight: bold;
}

.highlighted {
  color: #ff0041;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.sport-icon,
.flag-icon,
.node-icon,
.virtual-league-icon {
  background-repeat: no-repeat;
  display: block;
  background-size: 100% 100%;
}

.sport-icon.icon-0 {
  background-image: url("/assets/images/sport-icons/favourites.svg");
}
.sport-icon.icon-0.text-dark, .sport-icon.icon-0.text-light, .sport-icon.icon-0.brand, .sport-icon.icon-0.accent, .sport-icon.icon-0.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-0.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/favourites.svg");
}
.sport-icon.icon-0.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/favourites.svg");
}
.sport-icon.icon-0.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/favourites.svg");
}
.sport-icon.icon-0.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/favourites.svg");
}
.sport-icon.icon-0.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/favourites.svg");
}
.sport-icon.icon-1 {
  background-image: url("/assets/images/sport-icons/soccer.svg");
}
.sport-icon.icon-1.text-dark, .sport-icon.icon-1.text-light, .sport-icon.icon-1.brand, .sport-icon.icon-1.accent, .sport-icon.icon-1.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-1.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/soccer.svg");
}
.sport-icon.icon-1.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/soccer.svg");
}
.sport-icon.icon-1.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/soccer.svg");
}
.sport-icon.icon-1.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/soccer.svg");
}
.sport-icon.icon-1.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/soccer.svg");
}
.sport-icon.icon-2 {
  background-image: url("/assets/images/sport-icons/basketball.svg");
}
.sport-icon.icon-2.text-dark, .sport-icon.icon-2.text-light, .sport-icon.icon-2.brand, .sport-icon.icon-2.accent, .sport-icon.icon-2.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-2.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/basketball.svg");
}
.sport-icon.icon-2.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/basketball.svg");
}
.sport-icon.icon-2.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/basketball.svg");
}
.sport-icon.icon-2.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/basketball.svg");
}
.sport-icon.icon-2.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/basketball.svg");
}
.sport-icon.icon-3 {
  background-image: url("/assets/images/sport-icons/baseball.svg");
}
.sport-icon.icon-3.text-dark, .sport-icon.icon-3.text-light, .sport-icon.icon-3.brand, .sport-icon.icon-3.accent, .sport-icon.icon-3.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-3.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/baseball.svg");
}
.sport-icon.icon-3.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/baseball.svg");
}
.sport-icon.icon-3.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/baseball.svg");
}
.sport-icon.icon-3.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/baseball.svg");
}
.sport-icon.icon-3.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/baseball.svg");
}
.sport-icon.icon-4 {
  background-image: url("/assets/images/sport-icons/ice-hockey.svg");
}
.sport-icon.icon-4.text-dark, .sport-icon.icon-4.text-light, .sport-icon.icon-4.brand, .sport-icon.icon-4.accent, .sport-icon.icon-4.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-4.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/ice-hockey.svg");
}
.sport-icon.icon-4.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/ice-hockey.svg");
}
.sport-icon.icon-4.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/ice-hockey.svg");
}
.sport-icon.icon-4.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/ice-hockey.svg");
}
.sport-icon.icon-4.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/ice-hockey.svg");
}
.sport-icon.icon-5 {
  background-image: url("/assets/images/sport-icons/tennis.svg");
}
.sport-icon.icon-5.text-dark, .sport-icon.icon-5.text-light, .sport-icon.icon-5.brand, .sport-icon.icon-5.accent, .sport-icon.icon-5.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-5.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/tennis.svg");
}
.sport-icon.icon-5.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/tennis.svg");
}
.sport-icon.icon-5.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/tennis.svg");
}
.sport-icon.icon-5.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/tennis.svg");
}
.sport-icon.icon-5.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/tennis.svg");
}
.sport-icon.icon-6 {
  background-image: url("/assets/images/sport-icons/handball.svg");
}
.sport-icon.icon-6.text-dark, .sport-icon.icon-6.text-light, .sport-icon.icon-6.brand, .sport-icon.icon-6.accent, .sport-icon.icon-6.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-6.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/handball.svg");
}
.sport-icon.icon-6.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/handball.svg");
}
.sport-icon.icon-6.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/handball.svg");
}
.sport-icon.icon-6.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/handball.svg");
}
.sport-icon.icon-6.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/handball.svg");
}
.sport-icon.icon-7 {
  background-image: url("/assets/images/sport-icons/floorball.svg");
}
.sport-icon.icon-7.text-dark, .sport-icon.icon-7.text-light, .sport-icon.icon-7.brand, .sport-icon.icon-7.accent, .sport-icon.icon-7.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-7.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/floorball.svg");
}
.sport-icon.icon-7.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/floorball.svg");
}
.sport-icon.icon-7.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/floorball.svg");
}
.sport-icon.icon-7.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/floorball.svg");
}
.sport-icon.icon-7.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/floorball.svg");
}
.sport-icon.icon-8 {
  background-image: url("/assets/images/sport-icons/football-go.svg");
}
.sport-icon.icon-8.text-dark, .sport-icon.icon-8.text-light, .sport-icon.icon-8.brand, .sport-icon.icon-8.accent, .sport-icon.icon-8.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-8.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/football-go.svg");
}
.sport-icon.icon-8.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/football-go.svg");
}
.sport-icon.icon-8.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/football-go.svg");
}
.sport-icon.icon-8.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/football-go.svg");
}
.sport-icon.icon-8.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/football-go.svg");
}
.sport-icon.icon-9 {
  background-image: url("/assets/images/sport-icons/golf.svg");
}
.sport-icon.icon-9.text-dark, .sport-icon.icon-9.text-light, .sport-icon.icon-9.brand, .sport-icon.icon-9.accent, .sport-icon.icon-9.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-9.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/golf.svg");
}
.sport-icon.icon-9.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/golf.svg");
}
.sport-icon.icon-9.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/golf.svg");
}
.sport-icon.icon-9.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/golf.svg");
}
.sport-icon.icon-9.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/golf.svg");
}
.sport-icon.icon-11 {
  background-image: url("/assets/images/sport-icons/motor-sport.svg");
}
.sport-icon.icon-11.text-dark, .sport-icon.icon-11.text-light, .sport-icon.icon-11.brand, .sport-icon.icon-11.accent, .sport-icon.icon-11.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-11.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/motor-sport.svg");
}
.sport-icon.icon-11.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/motor-sport.svg");
}
.sport-icon.icon-11.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/motor-sport.svg");
}
.sport-icon.icon-11.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/motor-sport.svg");
}
.sport-icon.icon-11.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/motor-sport.svg");
}
.sport-icon.icon-13 {
  background-image: url("/assets/images/sport-icons/aussie-rules.svg");
}
.sport-icon.icon-13.text-dark, .sport-icon.icon-13.text-light, .sport-icon.icon-13.brand, .sport-icon.icon-13.accent, .sport-icon.icon-13.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-13.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/aussie-rules.svg");
}
.sport-icon.icon-13.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/aussie-rules.svg");
}
.sport-icon.icon-13.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/aussie-rules.svg");
}
.sport-icon.icon-13.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/aussie-rules.svg");
}
.sport-icon.icon-13.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/aussie-rules.svg");
}
.sport-icon.icon-14 {
  background-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-14.text-dark, .sport-icon.icon-14.text-light, .sport-icon.icon-14.brand, .sport-icon.icon-14.accent, .sport-icon.icon-14.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-14.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-14.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-14.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-14.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-14.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-15 {
  background-image: url("/assets/images/sport-icons/bandy.svg");
}
.sport-icon.icon-15.text-dark, .sport-icon.icon-15.text-light, .sport-icon.icon-15.brand, .sport-icon.icon-15.accent, .sport-icon.icon-15.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-15.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/bandy.svg");
}
.sport-icon.icon-15.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/bandy.svg");
}
.sport-icon.icon-15.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/bandy.svg");
}
.sport-icon.icon-15.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/bandy.svg");
}
.sport-icon.icon-15.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/bandy.svg");
}
.sport-icon.icon-16 {
  background-image: url("/assets/images/sport-icons/football.svg");
}
.sport-icon.icon-16.text-dark, .sport-icon.icon-16.text-light, .sport-icon.icon-16.brand, .sport-icon.icon-16.accent, .sport-icon.icon-16.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-16.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/football.svg");
}
.sport-icon.icon-16.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/football.svg");
}
.sport-icon.icon-16.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/football.svg");
}
.sport-icon.icon-16.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/football.svg");
}
.sport-icon.icon-16.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/football.svg");
}
.sport-icon.icon-17 {
  background-image: url("/assets/images/sport-icons/cycling.svg");
}
.sport-icon.icon-17.text-dark, .sport-icon.icon-17.text-light, .sport-icon.icon-17.brand, .sport-icon.icon-17.accent, .sport-icon.icon-17.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-17.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/cycling.svg");
}
.sport-icon.icon-17.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/cycling.svg");
}
.sport-icon.icon-17.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/cycling.svg");
}
.sport-icon.icon-17.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/cycling.svg");
}
.sport-icon.icon-17.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/cycling.svg");
}
.sport-icon.icon-19 {
  background-image: url("/assets/images/sport-icons/snooker.svg");
}
.sport-icon.icon-19.text-dark, .sport-icon.icon-19.text-light, .sport-icon.icon-19.brand, .sport-icon.icon-19.accent, .sport-icon.icon-19.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-19.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/snooker.svg");
}
.sport-icon.icon-19.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/snooker.svg");
}
.sport-icon.icon-19.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/snooker.svg");
}
.sport-icon.icon-19.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/snooker.svg");
}
.sport-icon.icon-19.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/snooker.svg");
}
.sport-icon.icon-20 {
  background-image: url("/assets/images/sport-icons/table-tennis.svg");
}
.sport-icon.icon-20.text-dark, .sport-icon.icon-20.text-light, .sport-icon.icon-20.brand, .sport-icon.icon-20.accent, .sport-icon.icon-20.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-20.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/table-tennis.svg");
}
.sport-icon.icon-20.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/table-tennis.svg");
}
.sport-icon.icon-20.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/table-tennis.svg");
}
.sport-icon.icon-20.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/table-tennis.svg");
}
.sport-icon.icon-20.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/table-tennis.svg");
}
.sport-icon.icon-21 {
  background-image: url("/assets/images/sport-icons/cricket.svg");
}
.sport-icon.icon-21.text-dark, .sport-icon.icon-21.text-light, .sport-icon.icon-21.brand, .sport-icon.icon-21.accent, .sport-icon.icon-21.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-21.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/cricket.svg");
}
.sport-icon.icon-21.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/cricket.svg");
}
.sport-icon.icon-21.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/cricket.svg");
}
.sport-icon.icon-21.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/cricket.svg");
}
.sport-icon.icon-21.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/cricket.svg");
}
.sport-icon.icon-22 {
  background-image: url("/assets/images/sport-icons/darts.svg");
}
.sport-icon.icon-22.text-dark, .sport-icon.icon-22.text-light, .sport-icon.icon-22.brand, .sport-icon.icon-22.accent, .sport-icon.icon-22.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-22.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/darts.svg");
}
.sport-icon.icon-22.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/darts.svg");
}
.sport-icon.icon-22.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/darts.svg");
}
.sport-icon.icon-22.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/darts.svg");
}
.sport-icon.icon-22.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/darts.svg");
}
.sport-icon.icon-23 {
  background-image: url("/assets/images/sport-icons/volleyball.svg");
}
.sport-icon.icon-23.text-dark, .sport-icon.icon-23.text-light, .sport-icon.icon-23.brand, .sport-icon.icon-23.accent, .sport-icon.icon-23.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-23.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/volleyball.svg");
}
.sport-icon.icon-23.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/volleyball.svg");
}
.sport-icon.icon-23.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/volleyball.svg");
}
.sport-icon.icon-23.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/volleyball.svg");
}
.sport-icon.icon-23.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/volleyball.svg");
}
.sport-icon.icon-24 {
  background-image: url("/assets/images/sport-icons/field-hockey.svg");
}
.sport-icon.icon-24.text-dark, .sport-icon.icon-24.text-light, .sport-icon.icon-24.brand, .sport-icon.icon-24.accent, .sport-icon.icon-24.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-24.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/field-hockey.svg");
}
.sport-icon.icon-24.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/field-hockey.svg");
}
.sport-icon.icon-24.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/field-hockey.svg");
}
.sport-icon.icon-24.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/field-hockey.svg");
}
.sport-icon.icon-24.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/field-hockey.svg");
}
.sport-icon.icon-25 {
  background-image: url("/assets/images/sport-icons/pool.svg");
}
.sport-icon.icon-25.text-dark, .sport-icon.icon-25.text-light, .sport-icon.icon-25.brand, .sport-icon.icon-25.accent, .sport-icon.icon-25.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-25.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/pool.svg");
}
.sport-icon.icon-25.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/pool.svg");
}
.sport-icon.icon-25.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/pool.svg");
}
.sport-icon.icon-25.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/pool.svg");
}
.sport-icon.icon-25.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/pool.svg");
}
.sport-icon.icon-26 {
  background-image: url("/assets/images/sport-icons/waterpolo.svg");
}
.sport-icon.icon-26.text-dark, .sport-icon.icon-26.text-light, .sport-icon.icon-26.brand, .sport-icon.icon-26.accent, .sport-icon.icon-26.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-26.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/waterpolo.svg");
}
.sport-icon.icon-26.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/waterpolo.svg");
}
.sport-icon.icon-26.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/waterpolo.svg");
}
.sport-icon.icon-26.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/waterpolo.svg");
}
.sport-icon.icon-26.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/waterpolo.svg");
}
.sport-icon.icon-28 {
  background-image: url("/assets/images/sport-icons/curling.svg");
}
.sport-icon.icon-28.text-dark, .sport-icon.icon-28.text-light, .sport-icon.icon-28.brand, .sport-icon.icon-28.accent, .sport-icon.icon-28.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-28.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/curling.svg");
}
.sport-icon.icon-28.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/curling.svg");
}
.sport-icon.icon-28.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/curling.svg");
}
.sport-icon.icon-28.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/curling.svg");
}
.sport-icon.icon-28.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/curling.svg");
}
.sport-icon.icon-29 {
  background-image: url("/assets/images/sport-icons/futsal.svg");
}
.sport-icon.icon-29.text-dark, .sport-icon.icon-29.text-light, .sport-icon.icon-29.brand, .sport-icon.icon-29.accent, .sport-icon.icon-29.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-29.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/futsal.svg");
}
.sport-icon.icon-29.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/futsal.svg");
}
.sport-icon.icon-29.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/futsal.svg");
}
.sport-icon.icon-29.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/futsal.svg");
}
.sport-icon.icon-29.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/futsal.svg");
}
.sport-icon.icon-30 {
  background-image: url("/assets/images/sport-icons/olympics.svg");
}
.sport-icon.icon-30.text-dark, .sport-icon.icon-30.text-light, .sport-icon.icon-30.brand, .sport-icon.icon-30.accent, .sport-icon.icon-30.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-30.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/olympics.svg");
}
.sport-icon.icon-30.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/olympics.svg");
}
.sport-icon.icon-30.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/olympics.svg");
}
.sport-icon.icon-30.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/olympics.svg");
}
.sport-icon.icon-30.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/olympics.svg");
}
.sport-icon.icon-31 {
  background-image: url("/assets/images/sport-icons/badminton.svg");
}
.sport-icon.icon-31.text-dark, .sport-icon.icon-31.text-light, .sport-icon.icon-31.brand, .sport-icon.icon-31.accent, .sport-icon.icon-31.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-31.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/badminton.svg");
}
.sport-icon.icon-31.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/badminton.svg");
}
.sport-icon.icon-31.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/badminton.svg");
}
.sport-icon.icon-31.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/badminton.svg");
}
.sport-icon.icon-31.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/badminton.svg");
}
.sport-icon.icon-32 {
  background-image: url("/assets/images/sport-icons/bowls.svg");
}
.sport-icon.icon-32.text-dark, .sport-icon.icon-32.text-light, .sport-icon.icon-32.brand, .sport-icon.icon-32.accent, .sport-icon.icon-32.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-32.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/bowls.svg");
}
.sport-icon.icon-32.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/bowls.svg");
}
.sport-icon.icon-32.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/bowls.svg");
}
.sport-icon.icon-32.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/bowls.svg");
}
.sport-icon.icon-32.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/bowls.svg");
}
.sport-icon.icon-34 {
  background-image: url("/assets/images/sport-icons/beach-volley.svg");
}
.sport-icon.icon-34.text-dark, .sport-icon.icon-34.text-light, .sport-icon.icon-34.brand, .sport-icon.icon-34.accent, .sport-icon.icon-34.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-34.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/beach-volley.svg");
}
.sport-icon.icon-34.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/beach-volley.svg");
}
.sport-icon.icon-34.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/beach-volley.svg");
}
.sport-icon.icon-34.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/beach-volley.svg");
}
.sport-icon.icon-34.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/beach-volley.svg");
}
.sport-icon.icon-36 {
  background-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-36.text-dark, .sport-icon.icon-36.text-light, .sport-icon.icon-36.brand, .sport-icon.icon-36.accent, .sport-icon.icon-36.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-36.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-36.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-36.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-36.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-36.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-37 {
  background-image: url("/assets/images/sport-icons/squash.svg");
}
.sport-icon.icon-37.text-dark, .sport-icon.icon-37.text-light, .sport-icon.icon-37.brand, .sport-icon.icon-37.accent, .sport-icon.icon-37.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-37.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/squash.svg");
}
.sport-icon.icon-37.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/squash.svg");
}
.sport-icon.icon-37.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/squash.svg");
}
.sport-icon.icon-37.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/squash.svg");
}
.sport-icon.icon-37.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/squash.svg");
}
.sport-icon.icon-40 {
  background-image: url("/assets/images/sport-icons/formula1.svg");
}
.sport-icon.icon-40.text-dark, .sport-icon.icon-40.text-light, .sport-icon.icon-40.brand, .sport-icon.icon-40.accent, .sport-icon.icon-40.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-40.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/formula1.svg");
}
.sport-icon.icon-40.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/formula1.svg");
}
.sport-icon.icon-40.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/formula1.svg");
}
.sport-icon.icon-40.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/formula1.svg");
}
.sport-icon.icon-40.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/formula1.svg");
}
.sport-icon.icon-43 {
  background-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-43.text-dark, .sport-icon.icon-43.text-light, .sport-icon.icon-43.brand, .sport-icon.icon-43.accent, .sport-icon.icon-43.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-43.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-43.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-43.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-43.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-43.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-44 {
  background-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-44.text-dark, .sport-icon.icon-44.text-light, .sport-icon.icon-44.brand, .sport-icon.icon-44.accent, .sport-icon.icon-44.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-44.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-44.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-44.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-44.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-44.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-45 {
  background-image: url("/assets/images/sport-icons/bobsleigh.svg");
}
.sport-icon.icon-45.text-dark, .sport-icon.icon-45.text-light, .sport-icon.icon-45.brand, .sport-icon.icon-45.accent, .sport-icon.icon-45.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-45.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/bobsleigh.svg");
}
.sport-icon.icon-45.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/bobsleigh.svg");
}
.sport-icon.icon-45.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/bobsleigh.svg");
}
.sport-icon.icon-45.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/bobsleigh.svg");
}
.sport-icon.icon-45.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/bobsleigh.svg");
}
.sport-icon.icon-46 {
  background-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-46.text-dark, .sport-icon.icon-46.text-light, .sport-icon.icon-46.brand, .sport-icon.icon-46.accent, .sport-icon.icon-46.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-46.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-46.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-46.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-46.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-46.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-47 {
  background-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-47.text-dark, .sport-icon.icon-47.text-light, .sport-icon.icon-47.brand, .sport-icon.icon-47.accent, .sport-icon.icon-47.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-47.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-47.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-47.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-47.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-47.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-48 {
  background-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-48.text-dark, .sport-icon.icon-48.text-light, .sport-icon.icon-48.brand, .sport-icon.icon-48.accent, .sport-icon.icon-48.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-48.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-48.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-48.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-48.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-48.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/winter-sports.svg");
}
.sport-icon.icon-49 {
  background-image: url("/assets/images/sport-icons/snowboard.svg");
}
.sport-icon.icon-49.text-dark, .sport-icon.icon-49.text-light, .sport-icon.icon-49.brand, .sport-icon.icon-49.accent, .sport-icon.icon-49.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-49.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/snowboard.svg");
}
.sport-icon.icon-49.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/snowboard.svg");
}
.sport-icon.icon-49.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/snowboard.svg");
}
.sport-icon.icon-49.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/snowboard.svg");
}
.sport-icon.icon-49.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/snowboard.svg");
}
.sport-icon.icon-50 {
  background-image: url("/assets/images/sport-icons/speed-skating.svg");
}
.sport-icon.icon-50.text-dark, .sport-icon.icon-50.text-light, .sport-icon.icon-50.brand, .sport-icon.icon-50.accent, .sport-icon.icon-50.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-50.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/speed-skating.svg");
}
.sport-icon.icon-50.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/speed-skating.svg");
}
.sport-icon.icon-50.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/speed-skating.svg");
}
.sport-icon.icon-50.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/speed-skating.svg");
}
.sport-icon.icon-50.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/speed-skating.svg");
}
.sport-icon.icon-51 {
  background-image: url("/assets/images/sport-icons/luge.svg");
}
.sport-icon.icon-51.text-dark, .sport-icon.icon-51.text-light, .sport-icon.icon-51.brand, .sport-icon.icon-51.accent, .sport-icon.icon-51.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-51.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/luge.svg");
}
.sport-icon.icon-51.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/luge.svg");
}
.sport-icon.icon-51.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/luge.svg");
}
.sport-icon.icon-51.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/luge.svg");
}
.sport-icon.icon-51.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/luge.svg");
}
.sport-icon.icon-59 {
  background-image: url("/assets/images/sport-icons/rugby-league.svg");
}
.sport-icon.icon-59.text-dark, .sport-icon.icon-59.text-light, .sport-icon.icon-59.brand, .sport-icon.icon-59.accent, .sport-icon.icon-59.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-59.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/rugby-league.svg");
}
.sport-icon.icon-59.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/rugby-league.svg");
}
.sport-icon.icon-59.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/rugby-league.svg");
}
.sport-icon.icon-59.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/rugby-league.svg");
}
.sport-icon.icon-59.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/rugby-league.svg");
}
.sport-icon.icon-60 {
  background-image: url("/assets/images/sport-icons/beach-soccer.svg");
}
.sport-icon.icon-60.text-dark, .sport-icon.icon-60.text-light, .sport-icon.icon-60.brand, .sport-icon.icon-60.accent, .sport-icon.icon-60.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-60.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/beach-soccer.svg");
}
.sport-icon.icon-60.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/beach-soccer.svg");
}
.sport-icon.icon-60.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/beach-soccer.svg");
}
.sport-icon.icon-60.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/beach-soccer.svg");
}
.sport-icon.icon-60.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/beach-soccer.svg");
}
.sport-icon.icon-61 {
  background-image: url("/assets/images/sport-icons/pesapallo.svg");
}
.sport-icon.icon-61.text-dark, .sport-icon.icon-61.text-light, .sport-icon.icon-61.brand, .sport-icon.icon-61.accent, .sport-icon.icon-61.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-61.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/pesapallo.svg");
}
.sport-icon.icon-61.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/pesapallo.svg");
}
.sport-icon.icon-61.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/pesapallo.svg");
}
.sport-icon.icon-61.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/pesapallo.svg");
}
.sport-icon.icon-61.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/pesapallo.svg");
}
.sport-icon.icon-80 {
  background-image: url("/assets/images/sport-icons/lotto.svg");
}
.sport-icon.icon-80.text-dark, .sport-icon.icon-80.text-light, .sport-icon.icon-80.brand, .sport-icon.icon-80.accent, .sport-icon.icon-80.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-80.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/lotto.svg");
}
.sport-icon.icon-80.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/lotto.svg");
}
.sport-icon.icon-80.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/lotto.svg");
}
.sport-icon.icon-80.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/lotto.svg");
}
.sport-icon.icon-80.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/lotto.svg");
}
.sport-icon.icon-81 {
  background-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-81.text-dark, .sport-icon.icon-81.text-light, .sport-icon.icon-81.brand, .sport-icon.icon-81.accent, .sport-icon.icon-81.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-81.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-81.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-81.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-81.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-81.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/athletics.svg");
}
.sport-icon.icon-83 {
  background-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-83.text-dark, .sport-icon.icon-83.text-light, .sport-icon.icon-83.brand, .sport-icon.icon-83.accent, .sport-icon.icon-83.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-83.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-83.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-83.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-83.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-83.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-85 {
  background-image: url("/assets/images/sport-icons/specials.svg");
}
.sport-icon.icon-85.text-dark, .sport-icon.icon-85.text-light, .sport-icon.icon-85.brand, .sport-icon.icon-85.accent, .sport-icon.icon-85.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-85.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/specials.svg");
}
.sport-icon.icon-85.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/specials.svg");
}
.sport-icon.icon-85.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/specials.svg");
}
.sport-icon.icon-85.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/specials.svg");
}
.sport-icon.icon-85.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/specials.svg");
}
.sport-icon.icon-86 {
  background-image: url("/assets/images/sport-icons/wrestling.svg");
}
.sport-icon.icon-86.text-dark, .sport-icon.icon-86.text-light, .sport-icon.icon-86.brand, .sport-icon.icon-86.accent, .sport-icon.icon-86.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-86.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/wrestling.svg");
}
.sport-icon.icon-86.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/wrestling.svg");
}
.sport-icon.icon-86.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/wrestling.svg");
}
.sport-icon.icon-86.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/wrestling.svg");
}
.sport-icon.icon-86.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/wrestling.svg");
}
.sport-icon.icon-87 {
  background-image: url("/assets/images/sport-icons/ufc.svg");
}
.sport-icon.icon-87.text-dark, .sport-icon.icon-87.text-light, .sport-icon.icon-87.brand, .sport-icon.icon-87.accent, .sport-icon.icon-87.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-87.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/ufc.svg");
}
.sport-icon.icon-87.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/ufc.svg");
}
.sport-icon.icon-87.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/ufc.svg");
}
.sport-icon.icon-87.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/ufc.svg");
}
.sport-icon.icon-87.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/ufc.svg");
}
.sport-icon.icon-99 {
  background-image: url("/assets/images/sport-icons/special-offers.svg");
}
.sport-icon.icon-99.text-dark, .sport-icon.icon-99.text-light, .sport-icon.icon-99.brand, .sport-icon.icon-99.accent, .sport-icon.icon-99.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-99.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/special-offers.svg");
}
.sport-icon.icon-99.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/special-offers.svg");
}
.sport-icon.icon-99.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/special-offers.svg");
}
.sport-icon.icon-99.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/special-offers.svg");
}
.sport-icon.icon-99.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/special-offers.svg");
}
.sport-icon.icon-101 {
  background-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-101.text-dark, .sport-icon.icon-101.text-light, .sport-icon.icon-101.brand, .sport-icon.icon-101.accent, .sport-icon.icon-101.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-101.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-101.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-101.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-101.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-101.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/boxing.svg");
}
.sport-icon.icon-103 {
  background-image: url("/assets/images/sport-icons/swimming.svg");
}
.sport-icon.icon-103.text-dark, .sport-icon.icon-103.text-light, .sport-icon.icon-103.brand, .sport-icon.icon-103.accent, .sport-icon.icon-103.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-103.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/swimming.svg");
}
.sport-icon.icon-103.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/swimming.svg");
}
.sport-icon.icon-103.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/swimming.svg");
}
.sport-icon.icon-103.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/swimming.svg");
}
.sport-icon.icon-103.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/swimming.svg");
}
.sport-icon.icon-106 {
  background-image: url("/assets/images/sport-icons/sailing.svg");
}
.sport-icon.icon-106.text-dark, .sport-icon.icon-106.text-light, .sport-icon.icon-106.brand, .sport-icon.icon-106.accent, .sport-icon.icon-106.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-106.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/sailing.svg");
}
.sport-icon.icon-106.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/sailing.svg");
}
.sport-icon.icon-106.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/sailing.svg");
}
.sport-icon.icon-106.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/sailing.svg");
}
.sport-icon.icon-106.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/sailing.svg");
}
.sport-icon.icon-109 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-109.text-dark, .sport-icon.icon-109.text-light, .sport-icon.icon-109.brand, .sport-icon.icon-109.accent, .sport-icon.icon-109.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-109.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-109.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-109.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-109.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-109.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-110 {
  background-image: url("/assets/images/sport-icons/poker.svg");
}
.sport-icon.icon-110.text-dark, .sport-icon.icon-110.text-light, .sport-icon.icon-110.brand, .sport-icon.icon-110.accent, .sport-icon.icon-110.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-110.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/poker.svg");
}
.sport-icon.icon-110.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/poker.svg");
}
.sport-icon.icon-110.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/poker.svg");
}
.sport-icon.icon-110.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/poker.svg");
}
.sport-icon.icon-110.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/poker.svg");
}
.sport-icon.icon-111 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-111.text-dark, .sport-icon.icon-111.text-light, .sport-icon.icon-111.brand, .sport-icon.icon-111.accent, .sport-icon.icon-111.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-111.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-111.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-111.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-111.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-111.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-112 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-112.text-dark, .sport-icon.icon-112.text-light, .sport-icon.icon-112.brand, .sport-icon.icon-112.accent, .sport-icon.icon-112.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-112.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-112.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-112.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-112.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-112.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-113 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-113.text-dark, .sport-icon.icon-113.text-light, .sport-icon.icon-113.brand, .sport-icon.icon-113.accent, .sport-icon.icon-113.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-113.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-113.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-113.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-113.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-113.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-114 {
  background-image: url("/assets/images/sport-icons/freestyle-skiing.svg");
}
.sport-icon.icon-114.text-dark, .sport-icon.icon-114.text-light, .sport-icon.icon-114.brand, .sport-icon.icon-114.accent, .sport-icon.icon-114.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-114.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/freestyle-skiing.svg");
}
.sport-icon.icon-114.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/freestyle-skiing.svg");
}
.sport-icon.icon-114.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/freestyle-skiing.svg");
}
.sport-icon.icon-114.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/freestyle-skiing.svg");
}
.sport-icon.icon-114.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/freestyle-skiing.svg");
}
.sport-icon.icon-117 {
  background-image: url("/assets/images/sport-icons/mma.svg");
}
.sport-icon.icon-117.text-dark, .sport-icon.icon-117.text-light, .sport-icon.icon-117.brand, .sport-icon.icon-117.accent, .sport-icon.icon-117.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-117.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/mma.svg");
}
.sport-icon.icon-117.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/mma.svg");
}
.sport-icon.icon-117.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/mma.svg");
}
.sport-icon.icon-117.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/mma.svg");
}
.sport-icon.icon-117.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/mma.svg");
}
.sport-icon.icon-118 {
  background-image: url("/assets/images/sport-icons/figure-skating.svg");
}
.sport-icon.icon-118.text-dark, .sport-icon.icon-118.text-light, .sport-icon.icon-118.brand, .sport-icon.icon-118.accent, .sport-icon.icon-118.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-118.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/figure-skating.svg");
}
.sport-icon.icon-118.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/figure-skating.svg");
}
.sport-icon.icon-118.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/figure-skating.svg");
}
.sport-icon.icon-118.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/figure-skating.svg");
}
.sport-icon.icon-118.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/figure-skating.svg");
}
.sport-icon.icon-119 {
  background-image: url("/assets/images/sport-icons/skeleton.svg");
}
.sport-icon.icon-119.text-dark, .sport-icon.icon-119.text-light, .sport-icon.icon-119.brand, .sport-icon.icon-119.accent, .sport-icon.icon-119.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-119.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/skeleton.svg");
}
.sport-icon.icon-119.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/skeleton.svg");
}
.sport-icon.icon-119.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/skeleton.svg");
}
.sport-icon.icon-119.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/skeleton.svg");
}
.sport-icon.icon-119.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/skeleton.svg");
}
.sport-icon.icon-120 {
  background-image: url("/assets/images/sport-icons/short-track-speed-skating.svg");
}
.sport-icon.icon-120.text-dark, .sport-icon.icon-120.text-light, .sport-icon.icon-120.brand, .sport-icon.icon-120.accent, .sport-icon.icon-120.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-120.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/short-track-speed-skating.svg");
}
.sport-icon.icon-120.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/short-track-speed-skating.svg");
}
.sport-icon.icon-120.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/short-track-speed-skating.svg");
}
.sport-icon.icon-120.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/short-track-speed-skating.svg");
}
.sport-icon.icon-120.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/short-track-speed-skating.svg");
}
.sport-icon.icon-135 {
  background-image: url("/assets/images/sport-icons/gaelic-football.svg");
}
.sport-icon.icon-135.text-dark, .sport-icon.icon-135.text-light, .sport-icon.icon-135.brand, .sport-icon.icon-135.accent, .sport-icon.icon-135.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-135.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/gaelic-football.svg");
}
.sport-icon.icon-135.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/gaelic-football.svg");
}
.sport-icon.icon-135.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/gaelic-football.svg");
}
.sport-icon.icon-135.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/gaelic-football.svg");
}
.sport-icon.icon-135.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/gaelic-football.svg");
}
.sport-icon.icon-137 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-137.text-dark, .sport-icon.icon-137.text-light, .sport-icon.icon-137.brand, .sport-icon.icon-137.accent, .sport-icon.icon-137.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-137.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-137.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-137.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-137.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-137.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-142 {
  background-image: url("/assets/images/sport-icons/formula-e.svg");
}
.sport-icon.icon-142.text-dark, .sport-icon.icon-142.text-light, .sport-icon.icon-142.brand, .sport-icon.icon-142.accent, .sport-icon.icon-142.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-142.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/formula-e.svg");
}
.sport-icon.icon-142.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/formula-e.svg");
}
.sport-icon.icon-142.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/formula-e.svg");
}
.sport-icon.icon-142.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/formula-e.svg");
}
.sport-icon.icon-142.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/formula-e.svg");
}
.sport-icon.icon-153 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-153.text-dark, .sport-icon.icon-153.text-light, .sport-icon.icon-153.brand, .sport-icon.icon-153.accent, .sport-icon.icon-153.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-153.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-153.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-153.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-153.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-153.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-155 {
  background-image: url("/assets/images/sport-icons/basketball-3x3.svg");
}
.sport-icon.icon-155.text-dark, .sport-icon.icon-155.text-light, .sport-icon.icon-155.brand, .sport-icon.icon-155.accent, .sport-icon.icon-155.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-155.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/basketball-3x3.svg");
}
.sport-icon.icon-155.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/basketball-3x3.svg");
}
.sport-icon.icon-155.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/basketball-3x3.svg");
}
.sport-icon.icon-155.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/basketball-3x3.svg");
}
.sport-icon.icon-155.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/basketball-3x3.svg");
}
.sport-icon.icon-166 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-166.text-dark, .sport-icon.icon-166.text-light, .sport-icon.icon-166.brand, .sport-icon.icon-166.accent, .sport-icon.icon-166.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-166.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-166.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-166.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-166.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-166.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-195 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-195.text-dark, .sport-icon.icon-195.text-light, .sport-icon.icon-195.brand, .sport-icon.icon-195.accent, .sport-icon.icon-195.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-195.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-195.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-195.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-195.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-195.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-200 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-200.text-dark, .sport-icon.icon-200.text-light, .sport-icon.icon-200.brand, .sport-icon.icon-200.accent, .sport-icon.icon-200.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-200.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-200.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-200.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-200.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-200.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-201 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-201.text-dark, .sport-icon.icon-201.text-light, .sport-icon.icon-201.brand, .sport-icon.icon-201.accent, .sport-icon.icon-201.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-201.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-201.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-201.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-201.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-201.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-202 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-202.text-dark, .sport-icon.icon-202.text-light, .sport-icon.icon-202.brand, .sport-icon.icon-202.accent, .sport-icon.icon-202.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-202.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-202.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-202.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-202.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-202.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-203 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-203.text-dark, .sport-icon.icon-203.text-light, .sport-icon.icon-203.brand, .sport-icon.icon-203.accent, .sport-icon.icon-203.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-203.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-203.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-203.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-203.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-203.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-204 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-204.text-dark, .sport-icon.icon-204.text-light, .sport-icon.icon-204.brand, .sport-icon.icon-204.accent, .sport-icon.icon-204.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-204.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-204.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-204.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-204.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-204.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-205 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-205.text-dark, .sport-icon.icon-205.text-light, .sport-icon.icon-205.brand, .sport-icon.icon-205.accent, .sport-icon.icon-205.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-205.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-205.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-205.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-205.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-205.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-206 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-206.text-dark, .sport-icon.icon-206.text-light, .sport-icon.icon-206.brand, .sport-icon.icon-206.accent, .sport-icon.icon-206.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-206.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-206.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-206.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-206.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-206.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-207 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-207.text-dark, .sport-icon.icon-207.text-light, .sport-icon.icon-207.brand, .sport-icon.icon-207.accent, .sport-icon.icon-207.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-207.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-207.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-207.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-207.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-207.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-208 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-208.text-dark, .sport-icon.icon-208.text-light, .sport-icon.icon-208.brand, .sport-icon.icon-208.accent, .sport-icon.icon-208.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-208.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-208.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-208.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-208.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-208.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-209 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-209.text-dark, .sport-icon.icon-209.text-light, .sport-icon.icon-209.brand, .sport-icon.icon-209.accent, .sport-icon.icon-209.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-209.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-209.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-209.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-209.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-209.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-210 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-210.text-dark, .sport-icon.icon-210.text-light, .sport-icon.icon-210.brand, .sport-icon.icon-210.accent, .sport-icon.icon-210.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-210.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-210.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-210.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-210.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-210.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-211 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-211.text-dark, .sport-icon.icon-211.text-light, .sport-icon.icon-211.brand, .sport-icon.icon-211.accent, .sport-icon.icon-211.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-211.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-211.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-211.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-211.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-211.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-212 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-212.text-dark, .sport-icon.icon-212.text-light, .sport-icon.icon-212.brand, .sport-icon.icon-212.accent, .sport-icon.icon-212.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-212.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-212.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-212.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-212.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-212.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-213 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-213.text-dark, .sport-icon.icon-213.text-light, .sport-icon.icon-213.brand, .sport-icon.icon-213.accent, .sport-icon.icon-213.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-213.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-213.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-213.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-213.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-213.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-214 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-214.text-dark, .sport-icon.icon-214.text-light, .sport-icon.icon-214.brand, .sport-icon.icon-214.accent, .sport-icon.icon-214.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-214.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-214.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-214.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-214.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-214.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-215 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-215.text-dark, .sport-icon.icon-215.text-light, .sport-icon.icon-215.brand, .sport-icon.icon-215.accent, .sport-icon.icon-215.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-215.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-215.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-215.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-215.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-215.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-216 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-216.text-dark, .sport-icon.icon-216.text-light, .sport-icon.icon-216.brand, .sport-icon.icon-216.accent, .sport-icon.icon-216.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-216.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-216.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-216.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-216.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-216.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-217 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-217.text-dark, .sport-icon.icon-217.text-light, .sport-icon.icon-217.brand, .sport-icon.icon-217.accent, .sport-icon.icon-217.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-217.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-217.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-217.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-217.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-217.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-218 {
  background-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-218.text-dark, .sport-icon.icon-218.text-light, .sport-icon.icon-218.brand, .sport-icon.icon-218.accent, .sport-icon.icon-218.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-218.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-218.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-218.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-218.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-218.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/e-sport.svg");
}
.sport-icon.icon-252 {
  background-image: url("/assets/images/sport-icons/soccerbet.svg");
}
.sport-icon.icon-252.text-dark, .sport-icon.icon-252.text-light, .sport-icon.icon-252.brand, .sport-icon.icon-252.accent, .sport-icon.icon-252.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-252.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/soccerbet.svg");
}
.sport-icon.icon-252.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/soccerbet.svg");
}
.sport-icon.icon-252.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/soccerbet.svg");
}
.sport-icon.icon-252.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/soccerbet.svg");
}
.sport-icon.icon-252.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/soccerbet.svg");
}
.sport-icon.icon-253 {
  background-image: url("/assets/images/sport-icons/fast-league.svg");
}
.sport-icon.icon-253.text-dark, .sport-icon.icon-253.text-light, .sport-icon.icon-253.brand, .sport-icon.icon-253.accent, .sport-icon.icon-253.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-253.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/fast-league.svg");
}
.sport-icon.icon-253.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/fast-league.svg");
}
.sport-icon.icon-253.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/fast-league.svg");
}
.sport-icon.icon-253.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/fast-league.svg");
}
.sport-icon.icon-253.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/fast-league.svg");
}
.sport-icon.icon-254 {
  background-image: url("/assets/images/sport-icons/rugby-union.svg");
}
.sport-icon.icon-254.text-dark, .sport-icon.icon-254.text-light, .sport-icon.icon-254.brand, .sport-icon.icon-254.accent, .sport-icon.icon-254.brand-light {
  background-image: none;
  height: 100%;
  width: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.sport-icon.icon-254.text-dark {
  background-color: #000;
  mask-image: url("/assets/images/sport-icons/rugby-union.svg");
}
.sport-icon.icon-254.text-light {
  background-color: #fff;
  mask-image: url("/assets/images/sport-icons/rugby-union.svg");
}
.sport-icon.icon-254.brand {
  background-color: rgba(0, 0, 0, 0.87);
  mask-image: url("/assets/images/sport-icons/rugby-union.svg");
}
.sport-icon.icon-254.accent {
  background-color: #ffc400;
  mask-image: url("/assets/images/sport-icons/rugby-union.svg");
}
.sport-icon.icon-254.brand-light {
  background-color: rgba(0, 12, 45, 0.87);
  mask-image: url("/assets/images/sport-icons/rugby-union.svg");
}

.flag-icon.icon-generic {
  background-image: url("/assets/images/flag-icons/Generic.svg");
}
.flag-icon.icon-11618383 {
  background-image: url("/assets/images/flag-icons/SpecialOffers.svg");
}
.flag-icon.icon-10000738 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10000781 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10000876 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10001020 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10012100 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10035932 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10954838 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10001305 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10010103 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10040080 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10001319 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10040900 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10001323 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10033021 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10102448 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10167817 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10034040 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10517454 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10596592 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10010611 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10178690 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10057795 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10596589 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10008107 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10001293 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10187775 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10433465 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10178691 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10057797 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10191199 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10584855 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10350733 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10031425 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10047742 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10732679 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10640121 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10207261 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10365542 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10030217 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-10457201 {
  background-image: url("/assets/images/flag-icons/Europe.svg");
}
.flag-icon.icon-11522603 {
  background-image: url("/assets/images/flag-icons/Albania.svg");
}
.flag-icon.icon-11522515 {
  background-image: url("/assets/images/flag-icons/Algeria.svg");
}
.flag-icon.icon-11522605 {
  background-image: url("/assets/images/flag-icons/Andorra.svg");
}
.flag-icon.icon-11522493 {
  background-image: url("/assets/images/flag-icons/Arab-Emirates.svg");
}
.flag-icon.icon-11522799 {
  background-image: url("/assets/images/flag-icons/Argentina.svg");
}
.flag-icon.icon-11518207 {
  background-image: url("/assets/images/flag-icons/Armenia.svg");
}
.flag-icon.icon-10001070 {
  background-image: url("/assets/images/flag-icons/Australia.svg");
}
.flag-icon.icon-10242867 {
  background-image: url("/assets/images/flag-icons/Australia.svg");
}
.flag-icon.icon-10604560 {
  background-image: url("/assets/images/flag-icons/Australia.svg");
}
.flag-icon.icon-10117288 {
  background-image: url("/assets/images/flag-icons/Australia.svg");
}
.flag-icon.icon-10457351 {
  background-image: url("/assets/images/flag-icons/Australia.svg");
}
.flag-icon.icon-10665846 {
  background-image: url("/assets/images/flag-icons/Australia.svg");
}
.flag-icon.icon-11522607 {
  background-image: url("/assets/images/flag-icons/Austria.svg");
}
.flag-icon.icon-11518815 {
  background-image: url("/assets/images/flag-icons/Azerbaijan.svg");
}
.flag-icon.icon-11521983 {
  background-image: url("/assets/images/flag-icons/Bahrain.svg");
}
.flag-icon.icon-11518826 {
  background-image: url("/assets/images/flag-icons/Belarus.svg");
}
.flag-icon.icon-11549434 {
  background-image: url("/assets/images/flag-icons/Belgium.svg");
}
.flag-icon.icon-11522614 {
  background-image: url("/assets/images/flag-icons/Belgium.svg");
}
.flag-icon.icon-10997806 {
  background-image: url("/assets/images/flag-icons/Bangladesh.svg");
}
.flag-icon.icon-11858700 {
  background-image: url("/assets/images/flag-icons/Bangladesh.svg");
}
.flag-icon.icon-10550807 {
  background-image: url("/assets/images/flag-icons/Belgium.svg");
}
.flag-icon.icon-10377642 {
  background-image: url("/assets/images/flag-icons/Belgium.svg");
}
.flag-icon.icon-10124517 {
  background-image: url("/assets/images/flag-icons/Belgium.svg");
}
.flag-icon.icon-11522805 {
  background-image: url("/assets/images/flag-icons/Bolivia.svg");
}
.flag-icon.icon-11518881 {
  background-image: url("/assets/images/flag-icons/Bosnia.svg");
}
.flag-icon.icon-10321728 {
  background-image: url("/assets/images/flag-icons/Brazil.svg");
}
.flag-icon.icon-10637173 {
  background-image: url("/assets/images/flag-icons/Bulgaria.svg");
}
.flag-icon.icon-11518832 {
  background-image: url("/assets/images/flag-icons/Bulgaria.svg");
}
.flag-icon.icon-11522525 {
  background-image: url("/assets/images/flag-icons/Cameroon.svg");
}
.flag-icon.icon-10006266 {
  background-image: url("/assets/images/flag-icons/Canada.svg");
}
.flag-icon.icon-10755436 {
  background-image: url("/assets/images/flag-icons/Canada.svg");
}
.flag-icon.icon-10523134 {
  background-image: url("/assets/images/flag-icons/Canada.svg");
}
.flag-icon.icon-10743541 {
  background-image: url("/assets/images/flag-icons/Canada.svg");
}
.flag-icon.icon-11522807 {
  background-image: url("/assets/images/flag-icons/Canada.svg");
}
.flag-icon.icon-11522809 {
  background-image: url("/assets/images/flag-icons/Chile.svg");
}
.flag-icon.icon-10305058 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-10833960 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-10205724 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-10392529 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-10034170 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-10155553 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-11521985 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-11506733 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-11525768 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-11552437 {
  background-image: url("/assets/images/flag-icons/China.svg");
}
.flag-icon.icon-11522814 {
  background-image: url("/assets/images/flag-icons/Colombia.svg");
}
.flag-icon.icon-11522819 {
  background-image: url("/assets/images/flag-icons/Costa-Rica.svg");
}
.flag-icon.icon-11518905 {
  background-image: url("/assets/images/flag-icons/Croatia.svg");
}
.flag-icon.icon-11522620 {
  background-image: url("/assets/images/flag-icons/Cyprus.svg");
}
.flag-icon.icon-10529895 {
  background-image: url("/assets/images/flag-icons/Czech.svg");
}
.flag-icon.icon-11518986 {
  background-image: url("/assets/images/flag-icons/Czech.svg");
}
.flag-icon.icon-10001133 {
  background-image: url("/assets/images/flag-icons/Denmark.svg");
}
.flag-icon.icon-10597811 {
  background-image: url("/assets/images/flag-icons/Denmark.svg");
}
.flag-icon.icon-10491219 {
  background-image: url("/assets/images/flag-icons/Denmark.svg");
}
.flag-icon.icon-11522821 {
  background-image: url("/assets/images/flag-icons/Ecuador.svg");
}
.flag-icon.icon-11522528 {
  background-image: url("/assets/images/flag-icons/Egypt.svg");
}
.flag-icon.icon-11522824 {
  background-image: url("/assets/images/flag-icons/El-Salvador.svg");
}
.flag-icon.icon-12455079 {
  background-image: url("/assets/images/flag-icons/electronic-leagues.svg");
}
.flag-icon.icon-12455080 {
  background-image: url("/assets/images/flag-icons/electronic-leagues.svg");
}
.flag-icon.icon-10000840 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-10853035 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-10482632 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-10388072 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-10688205 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-10664864 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-10015503 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-10402468 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-10490200 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-10409054 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-11476295 {
  background-image: url("/assets/images/flag-icons/England.svg");
}
.flag-icon.icon-11518997 {
  background-image: url("/assets/images/flag-icons/Estonia.svg");
}
.flag-icon.icon-12975156 {
  background-image: url("/assets/images/flag-icons/Ethiopia.svg");
}
.flag-icon.icon-11522624 {
  background-image: url("/assets/images/flag-icons/Faroe-Islands.svg");
}
.flag-icon.icon-10001141 {
  background-image: url("/assets/images/flag-icons/Finland.svg");
}
.flag-icon.icon-10480008 {
  background-image: url("/assets/images/flag-icons/Finland.svg");
}
.flag-icon.icon-10001102 {
  background-image: url("/assets/images/flag-icons/France.svg");
}
.flag-icon.icon-10161222 {
  background-image: url("/assets/images/flag-icons/France.svg");
}
.flag-icon.icon-10005670 {
  background-image: url("/assets/images/flag-icons/France.svg");
}
.flag-icon.icon-10476593 {
  background-image: url("/assets/images/flag-icons/France.svg");
}
.flag-icon.icon-10923479 {
  background-image: url("/assets/images/flag-icons/France.svg");
}
.flag-icon.icon-10014714 {
  background-image: url("/assets/images/flag-icons/France.svg");
}
.flag-icon.icon-10240145 {
  background-image: url("/assets/images/flag-icons/France.svg");
}
.flag-icon.icon-10125011 {
  background-image: url("/assets/images/flag-icons/France.svg");
}
.flag-icon.icon-10142231 {
  background-image: url("/assets/images/flag-icons/France.svg");
}
.flag-icon.icon-11519976 {
  background-image: url("/assets/images/flag-icons/FYR-Macedonia.svg");
}
.flag-icon.icon-10617348 {
  background-image: url("/assets/images/flag-icons/Georgia.svg");
}
.flag-icon.icon-11519008 {
  background-image: url("/assets/images/flag-icons/Georgia.svg");
}
.flag-icon.icon-10001005 {
  background-image: url("/assets/images/flag-icons/Germany.svg");
}
.flag-icon.icon-10066502 {
  background-image: url("/assets/images/flag-icons/Germany.svg");
}
.flag-icon.icon-11522531 {
  background-image: url("/assets/images/flag-icons/Ghana.svg");
}
.flag-icon.icon-10673442 {
  background-image: url("/assets/images/flag-icons/Gibraltar.svg");
}
.flag-icon.icon-11522634 {
  background-image: url("/assets/images/flag-icons/Gibraltar.svg");
}
.flag-icon.icon-11522628 {
  background-image: url("/assets/images/flag-icons/Greece.svg");
}
.flag-icon.icon-11522826 {
  background-image: url("/assets/images/flag-icons/Guatemala.svg");
}
.flag-icon.icon-11522636 {
  background-image: url("/assets/images/flag-icons/Holland.svg");
}
.flag-icon.icon-11522829 {
  background-image: url("/assets/images/flag-icons/Honduras.svg");
}
.flag-icon.icon-11521990 {
  background-image: url("/assets/images/flag-icons/Hong-Kong.svg");
}
.flag-icon.icon-11519050 {
  background-image: url("/assets/images/flag-icons/Hungary.svg");
}
.flag-icon.icon-11522642 {
  background-image: url("/assets/images/flag-icons/Iceland.svg");
}
.flag-icon.icon-10033242 {
  background-image: url("/assets/images/flag-icons/India.svg");
}
.flag-icon.icon-11521998 {
  background-image: url("/assets/images/flag-icons/India.svg");
}
.flag-icon.icon-11522007 {
  background-image: url("/assets/images/flag-icons/Indonesia.svg");
}
.flag-icon.icon-11522014 {
  background-image: url("/assets/images/flag-icons/Iran.svg");
}
.flag-icon.icon-11522649 {
  background-image: url("/assets/images/flag-icons/Israel.svg");
}
.flag-icon.icon-10000835 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10001315 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10001318 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10701414 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10850796 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10138620 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10001934 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10024536 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10024842 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10116292 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-10829661 {
  background-image: url("/assets/images/flag-icons/Italy.svg");
}
.flag-icon.icon-11522533 {
  background-image: url("/assets/images/flag-icons/Ivory-Coast.svg");
}
.flag-icon.icon-11522831 {
  background-image: url("/assets/images/flag-icons/Jamaica.svg");
}
.flag-icon.icon-10005758 {
  background-image: url("/assets/images/flag-icons/Japan.svg");
}
.flag-icon.icon-11522023 {
  background-image: url("/assets/images/flag-icons/Japan.svg");
}
.flag-icon.icon-11522032 {
  background-image: url("/assets/images/flag-icons/Jordan.svg");
}
.flag-icon.icon-11519971 {
  background-image: url("/assets/images/flag-icons/Kazakhstan.svg");
}
.flag-icon.icon-11522535 {
  background-image: url("/assets/images/flag-icons/Kenya.svg");
}
.flag-icon.icon-11519973 {
  background-image: url("/assets/images/flag-icons/Kosovo.svg");
}
.flag-icon.icon-11522035 {
  background-image: url("/assets/images/flag-icons/Kuwait.svg");
}
.flag-icon.icon-11521948 {
  background-image: url("/assets/images/flag-icons/Latvia.svg");
}
.flag-icon.icon-11522037 {
  background-image: url("/assets/images/flag-icons/Lebanon.svg");
}
.flag-icon.icon-11519957 {
  background-image: url("/assets/images/flag-icons/Lithuania.svg");
}
.flag-icon.icon-11522677 {
  background-image: url("/assets/images/flag-icons/Luxembourg.svg");
}
.flag-icon.icon-11522750 {
  background-image: url("/assets/images/flag-icons/Luxembourg.svg");
}
.flag-icon.icon-10700824 {
  background-image: url("/assets/images/flag-icons/Malaysia.svg");
}
.flag-icon.icon-11522039 {
  background-image: url("/assets/images/flag-icons/Malaysia.svg");
}
.flag-icon.icon-11522681 {
  background-image: url("/assets/images/flag-icons/Malta.svg");
}
.flag-icon.icon-10012848 {
  background-image: url("/assets/images/flag-icons/Mexico.svg");
}
.flag-icon.icon-11522833 {
  background-image: url("/assets/images/flag-icons/Mexico.svg");
}
.flag-icon.icon-11520001 {
  background-image: url("/assets/images/flag-icons/Moldova.svg");
}
.flag-icon.icon-11520008 {
  background-image: url("/assets/images/flag-icons/Montenegro.svg");
}
.flag-icon.icon-11522537 {
  background-image: url("/assets/images/flag-icons/Morocco.svg");
}
.flag-icon.icon-11522043 {
  background-image: url("/assets/images/flag-icons/Myanmar.svg");
}
.flag-icon.icon-10633533 {
  background-image: url("/assets/images/flag-icons/NewZealand.svg");
}
.flag-icon.icon-11522047 {
  background-image: url("/assets/images/flag-icons/NewZealand.svg");
}
.flag-icon.icon-11554942 {
  background-image: url("/assets/images/flag-icons/NewZealand.svg");
}
.flag-icon.icon-11529445 {
  background-image: url("/assets/images/flag-icons/NewZealand.svg");
}
.flag-icon.icon-11522863 {
  background-image: url("/assets/images/flag-icons/Nicaragua.svg");
}
.flag-icon.icon-11522540 {
  background-image: url("/assets/images/flag-icons/Nigeria.svg");
}
.flag-icon.icon-11522771 {
  background-image: url("/assets/images/flag-icons/Northern-Ireland.svg");
}
.flag-icon.icon-10001123 {
  background-image: url("/assets/images/flag-icons/Norway.svg");
}
.flag-icon.icon-11522049 {
  background-image: url("/assets/images/flag-icons/Oman.svg");
}
.flag-icon.icon-11522051 {
  background-image: url("/assets/images/flag-icons/Pakistan.svg");
}
.flag-icon.icon-11553439 {
  background-image: url("/assets/images/flag-icons/Palestine.svg");
}
.flag-icon.icon-11522839 {
  background-image: url("/assets/images/flag-icons/Panama.svg");
}
.flag-icon.icon-11522841 {
  background-image: url("/assets/images/flag-icons/Paraguay.svg");
}
.flag-icon.icon-11522846 {
  background-image: url("/assets/images/flag-icons/Peru.svg");
}
.flag-icon.icon-11522058 {
  background-image: url("/assets/images/flag-icons/Philippines.svg");
}
.flag-icon.icon-10188528 {
  background-image: url("/assets/images/flag-icons/Poland.svg");
}
.flag-icon.icon-11520010 {
  background-image: url("/assets/images/flag-icons/Poland.svg");
}
.flag-icon.icon-11522690 {
  background-image: url("/assets/images/flag-icons/Portugal.svg");
}
.flag-icon.icon-11522060 {
  background-image: url("/assets/images/flag-icons/Qatar.svg");
}
.flag-icon.icon-11481543 {
  background-image: url("/assets/images/flag-icons/Qatar.svg");
}
.flag-icon.icon-11522756 {
  background-image: url("/assets/images/flag-icons/Republic-of-Ireland.svg");
}
.flag-icon.icon-11520019 {
  background-image: url("/assets/images/flag-icons/Romania.svg");
}
.flag-icon.icon-11520022 {
  background-image: url("/assets/images/flag-icons/Russia.svg");
}
.flag-icon.icon-11522562 {
  background-image: url("/assets/images/flag-icons/Rwanda.svg");
}
.flag-icon.icon-11522747 {
  background-image: url("/assets/images/flag-icons/SanMarino.svg");
}
.flag-icon.icon-10335497 {
  background-image: url("/assets/images/flag-icons/SaudiArabia.svg");
}
.flag-icon.icon-11522062 {
  background-image: url("/assets/images/flag-icons/SaudiArabia.svg");
}
.flag-icon.icon-11522761 {
  background-image: url("/assets/images/flag-icons/Scotland.svg");
}
.flag-icon.icon-11522542 {
  background-image: url("/assets/images/flag-icons/Senegal.svg");
}
.flag-icon.icon-11520056 {
  background-image: url("/assets/images/flag-icons/Serbia.svg");
}
.flag-icon.icon-10295933 {
  background-image: url("/assets/images/flag-icons/Singapore.svg");
}
.flag-icon.icon-11522065 {
  background-image: url("/assets/images/flag-icons/Singapore.svg");
}
.flag-icon.icon-10876495 {
  background-image: url("/assets/images/flag-icons/Slovakia.svg");
}
.flag-icon.icon-11520079 {
  background-image: url("/assets/images/flag-icons/Slovakia.svg");
}
.flag-icon.icon-11520083 {
  background-image: url("/assets/images/flag-icons/Slovenia.svg");
}
.flag-icon.icon-10323863 {
  background-image: url("/assets/images/flag-icons/SouthAfrica.svg");
}
.flag-icon.icon-11019813 {
  background-image: url("/assets/images/flag-icons/SouthAfrica.svg");
}
.flag-icon.icon-11522544 {
  background-image: url("/assets/images/flag-icons/SouthAfrica.svg");
}
.flag-icon.icon-10170566 {
  background-image: url("/assets/images/flag-icons/SouthKorea.svg");
}
.flag-icon.icon-11522067 {
  background-image: url("/assets/images/flag-icons/SouthKorea.svg");
}
.flag-icon.icon-10001107 {
  background-image: url("/assets/images/flag-icons/Spain.svg");
}
.flag-icon.icon-10488004 {
  background-image: url("/assets/images/flag-icons/Spain.svg");
}
.flag-icon.icon-10244834 {
  background-image: url("/assets/images/flag-icons/Spain.svg");
}
.flag-icon.icon-11537430 {
  background-image: url("/assets/images/flag-icons/Sudan.svg");
}
.flag-icon.icon-10487831 {
  background-image: url("/assets/images/flag-icons/Sweden.svg");
}
.flag-icon.icon-10001161 {
  background-image: url("/assets/images/flag-icons/Sweden.svg");
}
.flag-icon.icon-10470301 {
  background-image: url("/assets/images/flag-icons/Switzerland.svg");
}
.flag-icon.icon-10205572 {
  background-image: url("/assets/images/flag-icons/Switzerland.svg");
}
.flag-icon.icon-10538429 {
  background-image: url("/assets/images/flag-icons/Switzerland.svg");
}
.flag-icon.icon-10156893 {
  background-image: url("/assets/images/flag-icons/Switzerland.svg");
}
.flag-icon.icon-11522730 {
  background-image: url("/assets/images/flag-icons/Switzerland.svg");
}
.flag-icon.icon-11522555 {
  background-image: url("/assets/images/flag-icons/Tanzania.svg");
}
.flag-icon.icon-10726914 {
  background-image: url("/assets/images/flag-icons/Thailand.svg");
}
.flag-icon.icon-11522491 {
  background-image: url("/assets/images/flag-icons/Thailand.svg");
}
.flag-icon.icon-11407750 {
  background-image: url("/assets/images/flag-icons/Thailand.svg");
}
.flag-icon.icon-11522557 {
  background-image: url("/assets/images/flag-icons/Tunisia.svg");
}
.flag-icon.icon-11522734 {
  background-image: url("/assets/images/flag-icons/Turkey.svg");
}
.flag-icon.icon-11522560 {
  background-image: url("/assets/images/flag-icons/Uganda.svg");
}
.flag-icon.icon-11521977 {
  background-image: url("/assets/images/flag-icons/Ukraine.svg");
}
.flag-icon.icon-11522857 {
  background-image: url("/assets/images/flag-icons/Uruguay.svg");
}
.flag-icon.icon-10010893 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10254451 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10001581 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10002869 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10010124 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10846524 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-11001641 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10856320 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10530898 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10146334 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10296614 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10469133 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10420094 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10530137 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-11522850 {
  background-image: url("/assets/images/flag-icons/USA-America.svg");
}
.flag-icon.icon-10000991 {
  background-image: url("/assets/images/flag-icons/UK-EIRE.svg");
}
.flag-icon.icon-10666361 {
  background-image: url("/assets/images/flag-icons/UK-EIRE.svg");
}
.flag-icon.icon-10498510 {
  background-image: url("/assets/images/flag-icons/UK-EIRE.svg");
}
.flag-icon.icon-10433596 {
  background-image: url("/assets/images/flag-icons/UK-EIRE.svg");
}
.flag-icon.icon-10493347 {
  background-image: url("/assets/images/flag-icons/UK-EIRE.svg");
}
.flag-icon.icon-10057732 {
  background-image: url("/assets/images/flag-icons/UK-EIRE.svg");
}
.flag-icon.icon-11522496 {
  background-image: url("/assets/images/flag-icons/Uzbekistan.svg");
}
.flag-icon.icon-11522860 {
  background-image: url("/assets/images/flag-icons/Venezuela.svg");
}
.flag-icon.icon-11522498 {
  background-image: url("/assets/images/flag-icons/Vietnam.svg");
}
.flag-icon.icon-10329546 {
  background-image: url("/assets/images/flag-icons/Wales.svg");
}
.flag-icon.icon-10028705 {
  background-image: url("/assets/images/flag-icons/Wales.svg");
}
.flag-icon.icon-11522789 {
  background-image: url("/assets/images/flag-icons/Wales.svg");
}
.flag-icon.icon-11522503 {
  background-image: url("/assets/images/flag-icons/Yemen.svg");
}
.flag-icon.icon-11522566 {
  background-image: url("/assets/images/flag-icons/Zambia.svg");
}
.flag-icon.icon-11522564 {
  background-image: url("/assets/images/flag-icons/Zimbabwe.svg");
}

.country-icon.icon-generic {
  background-image: url("/assets/images/flag-icons/Generic.svg");
}
.country-icon.icon-15266236 {
  background-image: url("/assets/images/country-icons/England.svg");
}
.country-icon.icon-15266238 {
  background-image: url("/assets/images/country-icons/Italy.svg");
}
.country-icon.icon-15266239 {
  background-image: url("/assets/images/country-icons/Germany.svg");
}
.country-icon.icon-15266240 {
  background-image: url("/assets/images/country-icons/Spain.svg");
}
.country-icon.icon-15266237 {
  background-image: url("/assets/images/country-icons/France.svg");
}
.country-icon.icon-15266353 {
  background-image: url("/assets/images/country-icons/Holland.svg");
}
.country-icon.icon-15266354 {
  background-image: url("/assets/images/country-icons/Nigeria.svg");
}
.country-icon.icon-15266355 {
  background-image: url("/assets/images/country-icons/Portugal.svg");
}
.country-icon.icon-15266480 {
  background-image: url("/assets/images/country-icons/Africa.svg");
}
.country-icon.icon-15266682 {
  background-image: url("/assets/images/country-icons/ChampionsLeague.svg");
}

.prefix-flag-icon {
  border-radius: 50%;
  overflow: hidden;
  background-position: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.prefix-flag-icon.icon-NGA {
  background-image: url("/assets/images/prefix-flag-icons/nigeria.svg");
}
.prefix-flag-icon.icon-KEN {
  background-image: url("/assets/images/prefix-flag-icons/kenya.svg");
}
.prefix-flag-icon.icon-GHA {
  background-image: url("/assets/images/prefix-flag-icons/ghana.svg");
}
.prefix-flag-icon.icon-ETH {
  background-image: url("/assets/images/prefix-flag-icons/ethiopia.svg");
}

.node-icon.icon-generic {
  background-image: url("/assets/images/node-icons/Generic.svg");
}
.node-icon.icon-1 {
  background-image: url("/assets/images/node-icons/antepost.svg");
}
.node-icon.icon-2 {
  background-image: url("/assets/images/node-icons/goalscorer.svg");
}
.node-icon.icon-3 {
  background-image: url("/assets/images/node-icons/oddsBoost.svg");
}
.node-icon.icon-4 {
  background-image: url("/assets/images/node-icons/generic.svg");
}
.node-icon.icon-5 {
  background-image: url("/assets/images/node-icons/electronic-leagues.svg");
}
.node-icon.icon-6 {
  background-image: url("/assets/images/node-icons/birthday-specials.svg");
}
.node-icon.icon-9 {
  background-image: url("/assets/images/node-icons/basketball-player-specials.svg");
}

.virtual-league-icon.icon-generic {
  background-image: url("/assets/images/virtuals-icons/league-icons/generic.svg");
}
.virtual-league-icon.icon-2660312 {
  background-image: url("/assets/images/virtuals-icons/league-icons/kings-league.svg");
}
.virtual-league-icon.icon-2660314 {
  background-image: url("/assets/images/virtuals-icons/league-icons/kings-liga.svg");
}
.virtual-league-icon.icon-3250280 {
  background-image: url("/assets/images/virtuals-icons/league-icons/kings-italiano.svg");
}
.virtual-league-icon.icon-16337569 {
  background-image: url("/assets/images/virtuals-icons/league-icons/kings-bundliga.svg");
}
.virtual-league-icon.icon-78777649 {
  background-image: url("/assets/images/virtuals-icons/league-icons/kings-bundliga.svg");
}
.virtual-league-icon.icon-72026820 {
  background-image: url("/assets/images/virtuals-icons/league-icons/kings-bundliga.svg");
}
.virtual-league-icon.icon-82610803 {
  background-image: url("/assets/images/virtuals-icons/league-icons/kings-bundliga.svg");
}

.hidden {
  display: none !important;
}

.blink {
  animation: blink 1s steps(1, end) infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cdk-overlay-connected-position-bounding-box {
  left: unset !important;
  width: unset !important;
  -webkit-overflow-scrolling: touch;
  /* For smoother scrolling on iOS */
}

.disable-container {
  pointer-events: none;
}

.pinned-to-top {
  position: fixed !important;
  top: var(--sibling-height) !important;
}

h1.download-view-title p {
  margin: 0;
}
h1.download-view-title p span {
  color: #1affff;
}

.download-view-instructions p span {
  display: block;
  padding-left: 15px;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .noscroll-mobile {
    overflow: hidden;
  }
}
app-tab {
  display: block;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06);
  position: relative;
}

.sticky {
  position: fixed;
  top: 56px;
  width: 100%;
  opacity: 1;
  background-color: #fff;
}
.sticky.sticky-info-line {
  top: 108px;
}
.sticky.sticky-info-line.no-market-tab {
  top: 56px;
}

.sticky-container {
  margin-top: 81.8px;
}

.sticky-0 {
  position: fixed;
  top: 0;
  width: 100%;
  opacity: 1;
  z-index: 5;
  margin-top: 0 !important;
  background-color: #fff;
}
.sticky-0.sticky-info-line {
  top: 52px;
}

@media (min-width: 900px) {
  .sticky-0 {
    top: 56px;
    padding-top: 10px;
  }
}
.coupon-status-0 {
  background-color: #fff;
}

.coupon-status-1 {
  background-color: #ffbd00;
}

.coupon-status-2 {
  background-color: #ff423d;
}
.coupon-status-2.lighten-background {
  background-color: #fff5f5;
}
.coupon-status-2.text-color {
  color: #ff423d;
}

.coupon-status-3 {
  background-color: #2da050;
}
.coupon-status-3.lighten-background {
  background-color: #e8f9ed;
}
.coupon-status-3.text-color {
  color: #2da050;
}

.coupon-status-4 {
  background-color: #c6c83b;
}

.bet-final-state--1 {
  background-color: #ffbd00;
}
.bet-final-state--1.for-lost-and-won {
  background: inherit;
  color: inherit;
}

.bet-final-state-0 {
  background-color: #ff9e47;
}
.bet-final-state-0.for-lost-and-won {
  background: inherit;
  color: inherit;
}

.bet-final-state-1 {
  background-color: #2da050;
}
.bet-final-state-1.lighten-background {
  background-color: #e8f9ed;
}
.bet-final-state-1.text-color {
  color: #2da050;
}

.bet-final-state-2 {
  background-color: #50e3c2;
}
.bet-final-state-2.lighten-background {
  background-color: #e8f9ed;
}
.bet-final-state-2.text-color {
  color: #2da050;
}

.bet-final-state-3 {
  background-color: #ff423d;
}
.bet-final-state-3.lighten-background {
  background-color: #ffebea;
}
.bet-final-state-3.text-color {
  color: #ff423d;
}

.bet-final-state-4.voided {
  background-color: #757575;
}
.bet-final-state-4.cancelled {
  background-color: #c6c83b;
}
.bet-final-state-4.for-lost-and-won {
  background: inherit;
  color: inherit;
}

.bet-final-state-5 {
  background-color: #bdb289;
}
.bet-final-state-5.for-lost-and-won {
  background: inherit;
  color: inherit;
}

.bet-final-state-6 {
  background-color: #86159d;
}
.bet-final-state-6.for-lost-and-won {
  background: inherit;
  color: inherit;
}

.odd-result-status--2 {
  background-color: #ffbd00;
}

.odd-result-status--1 {
  background-color: #757575;
}

.odd-result-status-0 {
  background-color: #ff423d;
}

.odd-result-status-1 {
  background-color: #2da050;
}

.odd-result-status-4 {
  background-color: #50e3c2;
}

/*
  Pitch noise fixes
*/
.sr-bb {
  /*
    Enable dark or light logo based on base color
  */
  text-align: left;
  background: #fff;
}
.sr-bb div[class*=__noise], .sr-bb div[class*=_texture] {
  background-repeat: repeat;
}
.sr-bb div[class*=stripes] {
  background-size: 14.575% 100%;
  background-repeat: repeat;
}

.sr-bb.sr-rtl {
  text-align: right;
}

.sr-bb .sr-bb {
  background: none;
}

.sr-bb .srt-base-1 {
  background-color: transparent;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-win {
  background-color: transparent;
  color: #0e1e78;
  border-color: #0e1e78;
}

.sr-bb .srt-base-1-draw {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-base-1-lose {
  background-color: transparent;
  color: #b68521;
  border-color: #b68521;
}

.sr-bb .srt-base-1-is-active {
  background-color: rgba(37, 94, 189, 0.12);
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
  background-color: #e6e6e6;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
  background-color: rgba(37, 94, 189, 0.12);
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-primary {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  border-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-base-1-home-1 {
  background-color: transparent;
  color: #0e1e78;
  border-color: #0e1e78;
}

.sr-bb .srt-base-1-away-1 {
  background-color: transparent;
  color: #b68521;
  border-color: #b68521;
}

.sr-bb .srt-base-1-home-2 {
  background-color: transparent;
  color: #0e1e78;
  border-color: #15376e;
}

.sr-bb .srt-base-1-away-2 {
  background-color: transparent;
  color: #b68521;
  border-color: #15376e;
}

.sr-bb .srt-base-1-home-3 {
  background-color: transparent;
  color: #0e1e78;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-away-3 {
  background-color: transparent;
  color: #b68521;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-home-4 {
  background-color: transparent;
  color: #5662a1;
  border-color: #5662a1;
}

.sr-bb .srt-base-1-away-4 {
  background-color: transparent;
  color: #ccaa64;
  border-color: #ccaa64;
}

.sr-bb .srt-base-1-home-5 {
  background-color: transparent;
  color: #09144e;
  border-color: #09144e;
}

.sr-bb .srt-base-1-away-5 {
  background-color: transparent;
  color: #765615;
  border-color: #765615;
}

.sr-bb .srt-base-1-background {
  background-color: white;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2 {
  background-color: white;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2-is-active {
  background-color: #e5ecf7;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
  background-color: #e5ecf7;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3 {
  background-color: #e5ecf7;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-is-active {
  background-color: #dae4f4;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
  background-color: #dae4f4;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-background {
  background-color: white;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-4 {
  background-color: #f4f7fc;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-5-is-active {
  background-color: #c6d5ee;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
  background-color: #c6d5ee;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-6 {
  background-color: transparent;
  color: #000;
  border-color: rgba(0, 0, 0, 0.3);
}

.sr-bb .srt-primary-1 {
  background-color: rgba(0, 0, 0, 0.38);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-active {
  background-color: #3b6ec4;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-hoverable:hover {
  background-color: #3b6ec4;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
  background-color: #517eca;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-2 {
  background-color: #1a4284;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-3 {
  background-color: #3b6ec4;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-4 {
  background-color: #7c9ed7;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-5 {
  background-color: #7c9ed7;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover {
  background-color: rgba(0, 0, 0, 0.38);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-6 {
  background-color: #15376e;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-7 {
  background-color: rgba(0, 0, 0, 0.38);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8 {
  background-color: rgba(0, 0, 0, 0.38);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8-is-active-1 {
  background-color: #3b6ec4;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
  background-color: #3b6ec4;
  color: #fff;
  border-color: #fff;
}

.sr-bb .srt-primary-9 {
  background-color: #becfeb;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-primary-10 {
  background-color: #517eca;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-11 {
  background-color: #3b6ec4;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-12 {
  background-color: rgba(0, 0, 0, 0.38);
  color: #fff;
  border-color: #fff;
}

.sr-bb .srt-primary-13 {
  background-color: #f4f7fc;
  color: #fff;
  border-color: rgba(37, 94, 189, 0.3);
}

.sr-bb .srt-base-1-primary-1 {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  border-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-base-1-primary-2 {
  background-color: transparent;
  color: #1a4284;
  border-color: #1a4284;
}

.sr-bb .srt-base-1-primary-3 {
  background-color: transparent;
  color: #3b6ec4;
  border-color: #3b6ec4;
}

.sr-bb .srt-base-1-primary-4 {
  background-color: transparent;
  color: #7c9ed7;
  border-color: #7c9ed7;
}

.sr-bb .srt-base-1-primary-5 {
  background-color: transparent;
  color: #7c9ed7;
  border-color: #7c9ed7;
}

.sr-bb .srt-base-1-primary-6 {
  background-color: transparent;
  color: #15376e;
  border-color: #15376e;
}

.sr-bb .srt-base-1-primary-7 {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  border-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-base-1-primary-8 {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  border-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-base-1-primary-9 {
  background-color: transparent;
  color: #becfeb;
  border-color: #becfeb;
}

.sr-bb .srt-base-1-primary-10 {
  background-color: transparent;
  color: #517eca;
  border-color: #517eca;
}

.sr-bb .srt-base-1-primary-11 {
  background-color: transparent;
  color: #3b6ec4;
  border-color: #3b6ec4;
}

.sr-bb .srt-base-1-primary-13 {
  background-color: #fff;
  color: #f4f7fc;
  border-color: rgba(37, 94, 189, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
  background-color: transparent;
  color: #dedede;
  border-color: #dedede;
}

.sr-bb .srt-base-1-neutral-2 {
  background-color: transparent;
  color: #1a1a1a;
  border-color: #1a1a1a;
}

.sr-bb .srt-base-1-neutral-3 {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
  background-color: transparent;
  color: #595959;
  border-color: #595959;
}

.sr-bb .srt-base-1-neutral-5 {
  background-color: transparent;
  color: #404040;
  border-color: #404040;
}

.sr-bb .srt-base-1-neutral-6 {
  background-color: transparent;
  color: #c2c2c2;
  border-color: #c2c2c2;
}

.sr-bb .srt-base-1-neutral-7 {
  background-color: transparent;
  color: #595959;
  border-color: #595959;
}

.sr-bb .srt-base-1-neutral-8 {
  background-color: transparent;
  color: #dedede;
  border-color: #dedede;
}

.sr-bb .srt-base-1-neutral-9 {
  background-color: transparent;
  color: #f0f0f0;
  border-color: #f0f0f0;
}

.sr-bb .srt-base-1-neutral-10 {
  background-color: transparent;
  color: #e0e0e0;
  border-color: #e0e0e0;
}

.sr-bb .srt-base-1-neutral-11 {
  background-color: transparent;
  color: #999;
  border-color: #999;
}

.sr-bb .srt-base-1-neutral-12 {
  background-color: transparent;
  color: #4d4d4d;
  border-color: #4d4d4d;
}

.sr-bb .srt-base-1-neutral-13 {
  background-color: transparent;
  color: #e6e6e6;
  border-color: #e6e6e6;
}

.sr-bb .srt-base-1-is-active-primary {
  background-color: rgba(37, 94, 189, 0.12);
  color: rgba(0, 0, 0, 0.38);
  border-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-base-1-is-active-home-1 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #0e1e78;
  border-color: #0e1e78;
}

.sr-bb .srt-base-1-is-active-away-1 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #b68521;
  border-color: #b68521;
}

.sr-bb .srt-base-1-is-active-home-2 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #0e1e78;
  border-color: #15376e;
}

.sr-bb .srt-base-1-is-active-away-2 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #b68521;
  border-color: #15376e;
}

.sr-bb .srt-base-1-is-active-home-3 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #0e1e78;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #b68521;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #5662a1;
  border-color: #5662a1;
}

.sr-bb .srt-base-1-is-active-away-4 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #ccaa64;
  border-color: #ccaa64;
}

.sr-bb .srt-base-1-is-active-home-5 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #09144e;
  border-color: #09144e;
}

.sr-bb .srt-base-1-is-active-away-5 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #765615;
  border-color: #765615;
}

.sr-bb .srt-base-1-is-active-primary-1 {
  background-color: rgba(37, 94, 189, 0.12);
  color: rgba(0, 0, 0, 0.38);
  border-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-base-1-is-active-primary-2 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #1a4284;
  border-color: #1a4284;
}

.sr-bb .srt-base-1-is-active-primary-3 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #3b6ec4;
  border-color: #3b6ec4;
}

.sr-bb .srt-base-1-is-active-primary-4 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #7c9ed7;
  border-color: #7c9ed7;
}

.sr-bb .srt-base-1-is-active-primary-5 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #7c9ed7;
  border-color: #7c9ed7;
}

.sr-bb .srt-base-1-is-active-primary-6 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #15376e;
  border-color: #15376e;
}

.sr-bb .srt-base-1-is-active-primary-7 {
  background-color: rgba(37, 94, 189, 0.12);
  color: rgba(0, 0, 0, 0.38);
  border-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-base-1-is-active-primary-8 {
  background-color: rgba(37, 94, 189, 0.12);
  color: rgba(0, 0, 0, 0.38);
  border-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-base-1-is-active-primary-9 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #becfeb;
  border-color: #becfeb;
}

.sr-bb .srt-base-1-is-active-primary-10 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #517eca;
  border-color: #517eca;
}

.sr-bb .srt-base-1-is-active-primary-11 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #3b6ec4;
  border-color: #3b6ec4;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #dedede;
  border-color: #dedede;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #1a1a1a;
  border-color: #1a1a1a;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
  background-color: rgba(37, 94, 189, 0.12);
  color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #595959;
  border-color: #595959;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #404040;
  border-color: #404040;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #c2c2c2;
  border-color: #c2c2c2;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #595959;
  border-color: #595959;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #dedede;
  border-color: #dedede;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #f0f0f0;
  border-color: #f0f0f0;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #e0e0e0;
  border-color: #e0e0e0;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #999;
  border-color: #999;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #4d4d4d;
  border-color: #4d4d4d;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #e6e6e6;
  border-color: #e6e6e6;
}

.sr-bb .srt-home-1 {
  background-color: #0e1e78;
  color: #fff;
  border-color: #0e1e78;
}

.sr-bb .srt-away-1 {
  background-color: #b68521;
  color: #000;
  border-color: #b68521;
}

.sr-bb .srt-home-2 {
  background-color: #0e1e78;
  color: #fff;
  border-color: #15376e;
}

.sr-bb .srt-away-2 {
  background-color: #b68521;
  color: #000;
  border-color: #15376e;
}

.sr-bb .srt-home-3 {
  background-color: #0e1e78;
  color: #fff;
  border-color: #fff;
}

.sr-bb .srt-away-3 {
  background-color: #b68521;
  color: #000;
  border-color: #fff;
}

.sr-bb .srt-home-4 {
  background-color: #5662a1;
  color: #fff;
  border-color: #5662a1;
}

.sr-bb .srt-away-4 {
  background-color: #ccaa64;
  color: #000;
  border-color: #ccaa64;
}

.sr-bb .srt-home-5 {
  background-color: #09144e;
  color: #fff;
  border-color: #09144e;
}

.sr-bb .srt-away-5 {
  background-color: #765615;
  color: #000;
  border-color: #765615;
}

.sr-bb .srt-home-6 {
  background-color: rgba(14, 30, 120, 0.2);
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-away-6 {
  background-color: rgba(182, 133, 33, 0.2);
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-home-6-is-hoverable:hover {
  background-color: rgba(14, 30, 120, 0.2);
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-away-6-is-hoverable:hover {
  background-color: rgba(182, 133, 33, 0.2);
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-1 {
  background-color: #dedede;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-2 {
  background-color: #1a1a1a;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-3 {
  background-color: rgba(0, 0, 0, 0.12);
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-4 {
  background-color: #595959;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-5 {
  background-color: #404040;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-6 {
  background-color: #c2c2c2;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-7 {
  background-color: #595959;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-8 {
  background-color: #dedede;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-9 {
  background-color: #f0f0f0;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-10 {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.82);
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-11 {
  background-color: #999;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-12 {
  background-color: #4d4d4d;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-13 {
  background-color: #e6e6e6;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-win {
  background-color: #0e1e78;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-draw {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-lose {
  background-color: #b68521;
  color: #000;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-text-secondary {
  opacity: 0.7;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-text-disabled {
  opacity: 0.35;
}

.sr-bb .srt-text-tertiary {
  opacity: 0.54;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
  opacity: 0.6;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
  opacity: 0.7;
}

.sr-bb .srt-icon {
  opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
  opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.26), 0 10 20 0 rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
  box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
  box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
  box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
  box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
  fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
  stroke: #2579ad;
}

.sr-bb .srt-fill-soccer-yellow-card {
  fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
  stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
  stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
  stroke: #4fbe30;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
  fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
  fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
  fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
  stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
  fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
  stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
  fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
  stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
  fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
  stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
  fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
  stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
  fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
  stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
  fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
  stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
  fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
  stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
  fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
  stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
  fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
  stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
  background-color: #f5a623;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
  background-color: rgba(245, 166, 35, 0.3);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-penalty {
  background-color: #f4a621;
  color: #fff;
  border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
  background-color: #ac182e;
  color: #fff;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
  background-color: #ac182e;
  color: #fff;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
  background-color: #4b90de;
  color: #fff;
  border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
  background-color: #f5a623;
  color: #fff;
  border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
  fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
  stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
  fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
  stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
  fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
  stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
  background-color: #090;
  color: #fff;
  border-color: #090;
}

.sr-bb .srt-mlb-run-2 {
  background-color: #fff;
  color: #090;
  border-color: #090;
}

.sr-bb .srt-mlb-hit-1 {
  background-color: #f7ab11;
  color: #000;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
  background-color: #fff;
  color: #f7ab11;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
  background-color: #00a4ff;
  color: #fff;
  border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
  background-color: #d0021b;
  color: #fff;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
  background-color: #fff;
  color: #d0021b;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
  background-color: #212121;
  color: #fff;
  border-color: #fff;
}

.sr-bb .srt-fill-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
  fill: #0e1e78;
}

.sr-bb .srt-stroke-change-increase {
  stroke: #0e1e78;
}

.sr-bb .srt-fill-change-decrease {
  fill: #b68521;
}

.sr-bb .srt-stroke-change-decrease {
  stroke: #b68521;
}

.sr-bb .srt-fill-text {
  fill: #000;
}

.sr-bb .srt-fill-text-invert {
  fill: #fff;
}

.sr-bb .srt-fill-text-secondary {
  fill-opacity: 0.7;
}

.sr-bb .srt-fill-text-disabled {
  fill-opacity: 0.35;
}

.sr-bb .srt-fill-base-1 {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
  fill: rgba(37, 94, 189, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
  stroke: rgba(37, 94, 189, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
  fill: #e6e6e6;
}

.sr-bb .srt-stroke-base-1-active-2 {
  stroke: #e6e6e6;
}

.sr-bb .srt-fill-base-1-primary {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
  stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
  fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
  stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
  fill: #e5ecf7;
}

.sr-bb .srt-stroke-base-2-active {
  stroke: #e5ecf7;
}

.sr-bb .srt-fill-base-2-hover {
  fill: #e5ecf7;
}

.sr-bb .srt-stroke-base-2-hover {
  stroke: #e5ecf7;
}

.sr-bb .srt-fill-base-3 {
  fill: #e5ecf7;
}

.sr-bb .srt-stroke-base-3 {
  stroke: #e5ecf7;
}

.sr-bb .srt-fill-base-3-active {
  fill: #dae4f4;
}

.sr-bb .srt-stroke-base-3-active {
  stroke: #dae4f4;
}

.sr-bb .srt-fill-base-3-hover {
  fill: #dae4f4;
}

.sr-bb .srt-stroke-base-3-hover {
  stroke: #dae4f4;
}

.sr-bb .srt-fill-primary-1 {
  fill: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-stroke-primary-1 {
  stroke: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-fill-primary-2 {
  fill: #1a4284;
}

.sr-bb .srt-stroke-primary-2 {
  stroke: #1a4284;
}

.sr-bb .srt-fill-primary-3 {
  fill: #3b6ec4;
}

.sr-bb .srt-stroke-primary-3 {
  stroke: #3b6ec4;
}

.sr-bb .srt-fill-primary-4 {
  fill: #7c9ed7;
}

.sr-bb .srt-stroke-primary-4 {
  stroke: #7c9ed7;
}

.sr-bb .srt-fill-primary-5 {
  fill: #7c9ed7;
}

.sr-bb .srt-stroke-primary-5 {
  stroke: #7c9ed7;
}

.sr-bb .srt-fill-primary-6 {
  fill: #15376e;
}

.sr-bb .srt-stroke-primary-6 {
  stroke: #15376e;
}

.sr-bb .srt-fill-primary-7 {
  fill: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-stroke-primary-7 {
  stroke: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-fill-primary-8 {
  fill: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-stroke-primary-8 {
  stroke: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-fill-primary-8-is-active-1 {
  fill: #3b6ec4;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
  stroke: #3b6ec4;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
  fill: #3b6ec4;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
  stroke: #3b6ec4;
}

.sr-bb .srt-fill-primary-9 {
  fill: #becfeb;
}

.sr-bb .srt-stroke-primary-9 {
  stroke: #becfeb;
}

.sr-bb .srt-fill-primary-10 {
  fill: #517eca;
}

.sr-bb .srt-stroke-primary-10 {
  stroke: #517eca;
}

.sr-bb .srt-fill-primary-11 {
  fill: #3b6ec4;
}

.sr-bb .srt-stroke-primary-11 {
  stroke: #3b6ec4;
}

.sr-bb .srt-fill-primary-12 {
  fill: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-stroke-primary-12 {
  stroke: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-fill-home-1 {
  fill: #0e1e78;
}

.sr-bb .srt-stroke-home-1 {
  stroke: #0e1e78;
}

.sr-bb .srt-fill-home-2 {
  fill: #0e1e78;
}

.sr-bb .srt-stroke-home-2 {
  stroke: #0e1e78;
}

.sr-bb .srt-fill-home-3 {
  fill: #0e1e78;
}

.sr-bb .srt-stroke-home-3 {
  stroke: #0e1e78;
}

.sr-bb .srt-fill-home-4 {
  fill: #5662a1;
}

.sr-bb .srt-stroke-home-4 {
  stroke: #5662a1;
}

.sr-bb .srt-fill-home-5 {
  fill: #09144e;
}

.sr-bb .srt-stroke-home-5 {
  stroke: #09144e;
}

.sr-bb .srt-fill-away-1 {
  fill: #b68521;
}

.sr-bb .srt-stroke-away-1 {
  stroke: #b68521;
}

.sr-bb .srt-fill-away-2 {
  fill: #b68521;
}

.sr-bb .srt-stroke-away-2 {
  stroke: #b68521;
}

.sr-bb .srt-fill-away-3 {
  fill: #b68521;
}

.sr-bb .srt-stroke-away-3 {
  stroke: #b68521;
}

.sr-bb .srt-fill-away-4 {
  fill: #ccaa64;
}

.sr-bb .srt-stroke-away-4 {
  stroke: #ccaa64;
}

.sr-bb .srt-fill-away-5 {
  fill: #765615;
}

.sr-bb .srt-stroke-away-5 {
  stroke: #765615;
}

.sr-bb .srt-fill-neutral-1 {
  fill: #dedede;
}

.sr-bb .srt-stroke-neutral-1 {
  stroke: #dedede;
}

.sr-bb .srt-fill-neutral-2 {
  fill: #1a1a1a;
}

.sr-bb .srt-stroke-neutral-2 {
  stroke: #1a1a1a;
}

.sr-bb .srt-fill-neutral-3 {
  fill: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
  stroke: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
  fill: #595959;
}

.sr-bb .srt-stroke-neutral-4 {
  stroke: #595959;
}

.sr-bb .srt-fill-neutral-5 {
  fill: #404040;
}

.sr-bb .srt-stroke-neutral-5 {
  stroke: #404040;
}

.sr-bb .srt-fill-neutral-6 {
  fill: #c2c2c2;
}

.sr-bb .srt-stroke-neutral-6 {
  stroke: #c2c2c2;
}

.sr-bb .srt-fill-neutral-7 {
  fill: #595959;
}

.sr-bb .srt-stroke-neutral-7 {
  stroke: #595959;
}

.sr-bb .srt-fill-neutral-8 {
  fill: #dedede;
}

.sr-bb .srt-stroke-neutral-8 {
  stroke: #dedede;
}

.sr-bb .srt-fill-neutral-9 {
  fill: #f0f0f0;
}

.sr-bb .srt-stroke-neutral-9 {
  stroke: #f0f0f0;
}

.sr-bb .srt-fill-neutral-10 {
  fill: #e0e0e0;
}

.sr-bb .srt-stroke-neutral-10 {
  stroke: #e0e0e0;
}

.sr-bb .srt-fill-neutral-11 {
  fill: #999;
}

.sr-bb .srt-stroke-neutral-11 {
  stroke: #999;
}

.sr-bb .srt-fill-neutral-12 {
  fill: #4d4d4d;
}

.sr-bb .srt-stroke-neutral-12 {
  stroke: #4d4d4d;
}

.sr-bb .srt-fill-neutral-13 {
  fill: #e6e6e6;
}

.sr-bb .srt-stroke-neutral-13 {
  stroke: #e6e6e6;
}

.sr-bb .srt-fill-win {
  fill: #0e1e78;
}

.sr-bb .srt-stroke-win {
  stroke: #0e1e78;
}

.sr-bb .srt-fill-draw {
  fill: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-stroke-draw {
  stroke: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-fill-lose {
  fill: #b68521;
}

.sr-bb .srt-stroke-lose {
  stroke: #b68521;
}

.sr-bb .srt-stop-base-1 {
  stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
  stop-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-stop-primary-2 {
  stop-color: #1a4284;
}

.sr-bb .srt-stop-primary-3 {
  stop-color: #3b6ec4;
}

.sr-bb .srt-stop-primary-4 {
  stop-color: #7c9ed7;
}

.sr-bb .srt-stop-primary-5 {
  stop-color: #7c9ed7;
}

.sr-bb .srt-stop-primary-6 {
  stop-color: #15376e;
}

.sr-bb .srt-stop-primary-7 {
  stop-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-stop-primary-8 {
  stop-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-stop-primary-9 {
  stop-color: #becfeb;
}

.sr-bb .srt-stop-primary-10 {
  stop-color: #517eca;
}

.sr-bb .srt-stop-primary-11 {
  stop-color: #3b6ec4;
}

.sr-bb .srt-stop-primary-12 {
  stop-color: rgba(0, 0, 0, 0.38);
}

.sr-bb .srt-stop-home-1 {
  stop-color: #0e1e78;
}

.sr-bb .srt-stop-away-1 {
  stop-color: #b68521;
}

.sr-bb .srt-fill-neutral-14 {
  fill: #fff;
}

.sr-bb .srt-stroke-neutral-14 {
  stroke: #fff;
}

.sr-bb .srt-logo-powered-by-light {
  display: inline-block;
}

.sr-bb .srt-logo-powered-by-dark {
  display: none;
}

.odds-boost-badge {
  background-image: url("/assets/images/odds-boost/odds-boost-icon.svg");
  background-color: #ffc400;
  background-position: center;
  background-size: 12px 12px;
  border-radius: 14px;
  border: 2px solid white;
  display: block;
  height: 20px;
  overflow: visible;
  width: 20px;
}

.badge {
  background-color: #ff0041;
  color: #fff;
  font-family: "Roboto Condensed", "Arial Narrow", sans-serif;
  font-weight: bold;
  padding-top: 2px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  z-index: 1;
  line-height: 0;
}

.badge-inverse {
  background-color: #fff;
  color: #255dbd;
  font-family: "Roboto Condensed", "Arial Narrow", sans-serif;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  z-index: 1;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.badge-red {
  background-color: #ff0041;
  color: #fff;
  font-family: "Roboto Condensed", "Arial Narrow", sans-serif;
  text-align: center;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: 4px;
  z-index: 1;
  font-weight: bold;
  padding: 0 2px;
}

html {
  min-width: 300px;
  font-family: "Roboto Condensed", "Arial Narrow", sans-serif;
  font-size: 12px;
  color: #000;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  min-width: 300px;
  background-color: #001041;
  height: 100%;
}